.first {
    
	color: #515f65;
	font-family: "League Spartan", sans-serif;
	font-weight: bold;
	display: flex;
        flex-direction: column;
    

}
.firstSectionMainTxt {
	font-size: 66px;
    height: 55px;
    width: 700px;
    margin-top: -12px;
}

.firstSectionSubTxt {
	font-size: 35px;
	margin-top: 50px;
}

.shortLine{
    width: 50px !important;
    margin-top: 10px !important;
}

.lastLine{
    margin-top: 50px;
}
.second {
   

	
	color: #515f65;
	font-weight: bold;
	
}
.secondTextList{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.secondBody{
   
    display: flex;
	flex-direction: column;
	overflow-y: auto;
	align-items: center;
	text-align: center;
}

.colorLine {
	margin-top: 50px;
	width: 150px;
	height: 6px;
	border-radius: 30px;
	background: #0088BC;
	background: linear-gradient(
		260deg,
		#0088BC 0%,
		#99CE6E 100%
	);
}

.secondSectionGreyTxt {
	font-size: 24px;
	margin-top: 20px;
    width: 600px;  
    text-align: justify; 
}

.secondSectionGreenTxt {
	color: #94c66b;
	font-size: 24px;
    width: 500px;
}

.welcome {
	margin-top: 50px !important;
}

.third {
	color: #515f65;
	font-weight: bold;
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
}

.table {
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	margin-left: 10px;
    width: 1350px;
}

.tableItem {
	width: 450px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableItemText {
	width: 340px;
}

.tableLine {
	background-color: #1b80af;
	height: 200px;
	width: 3px;
	border-radius: 35px;
}

.colorLineTable {
	
}

.colorLineTable2 {
	margin-top: 50px;
}

.fourth {
	
	 display: flex;
     align-items: center;
     color: #515f65;
     font-family: "League Spartan", sans-serif;
     font-weight: bold;
     overflow: hidden;
   
}

.endSpace{
    height: 200px;
    width: 100%;
}
 
.fourthSectionElements {

    color: #515f65;
	font-weight: bold;
}

.fourthSectionTitle {
	font-family: "League Spartan", sans-serif;
	font-weight: bold;
	font-size: 56px;
    text-align: center;
    width: 500px;
    height: 50px;
    
}

.fourthColorLine{


    width: 880px;

}

.fourthSectionItemList {
	
	font-size: 25px;
	display: flex;
	flex-direction: column;
    align-items: start;
    margin-top: 40px;
    margin-left: 100px;
    
}

.fourthSectionTxt{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.check{
    color: #94c66b;
}

.docName{
    margin-left: 20px;
}
.sectionBreak {
	width: 100%;
	height: 10px;
	border-radius: 30px;
    margin-top: 200px;
	background: rgb(35, 100, 159);
	background: linear-gradient(
		260deg,
		rgba(35, 100, 159, 1) 0%,
		rgba(23, 131, 51, 1) 100%
	);
}

.fifthSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #515f65;
    overflow-x: hidden;
    margin-top: 900px;
    
   
   
}

.fifthSectionFirstCol{
    margin-left: 200px;
    width: calc( 50% - 100px );
    height: 550px;
   
}

.fifthSectionFirstColTitle{
    font-size: 50px;
  
	font-family: "League Spartan", sans-serif;
    font-weight: bold;
}

.fifthSectionFirstColTxt{
    margin-top: 20px;
    width: 700px;
    font-size: 28px;
}

.fifthSectionSecCol{
    margin-left: auto;
    margin-right: 100px;
    width: 1000px;

    height: 550px;
}

.companyInfo{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 450px;
}

.roundLogo{
    width: 300px;
    margin-left: -80px;
}

.companyName{
    font-size: 30px;
    font-family: "League Spartan", sans-serif;
    font-weight: bold;
}

.companyLinkItem{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.companyLinkTxt{
    margin-left: 5px;
}

.fifthSectionSecColTitle{
    font-size: 50px;
  
	font-family: "League Spartan", sans-serif;
    font-weight: bold;
}

.fifthSectionSecColTxt{
    font-size: 28px;
}
.fifthSectionSecColForm{
background: rgb(35, 100, 159);
background: linear-gradient(
    90deg,
    rgba(35, 100, 159, 1) 0%,
    rgba(23, 131, 51, 1) 100%
);
border-radius: 10px;
height: 400px;
width: 100%;
margin-top: 20px;
display: flex;
flex-direction: column;
z-index: 100;
}



.fifthSectionSecColItem{
    display: flex;
    flex-direction: row;
    color: white;
    margin-left: 20px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 28px;
}

.emailInput{
    width: 550px;
    margin-left: auto;
    margin-right: 20px;
    height: 45px;
    background-color: white;
}


.textarea{
    margin-left: auto;
    margin-right: 20px;
    width:550px;
    max-height: 155px;
    height: 125px;
    border-radius: 10px;
}

.sendBut{
    width: 150px;
    height: 50px;
    background-color: transparent;
    margin-left: 30%;
    margin-top: 20px;
 
    border: 2px solid rgba(255,255,255,.5);
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    backdrop-filter: blur(300px);
    color: white;
    font-size: 28px;
    box-shadow: 0 0 30px rgba(0,0,0,.5);
}

.block{
    display: flex;
    flex-direction: row;
    height: calc( 100vh - 110px);
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.mainPart{
    overflow-y: hidden;
    height: 100%;
    position: relative;
    
    
}

.slideBut{

height: 60px;
width: 60px;
border-radius: 50%;

margin-bottom: 30px;
border:none;
z-index: 10;
background-color: inherit

}

.downBut{
    margin-bottom: 0px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.nextBut{
    left: auto;
    right:10px;
    
}

.butArr{
    color: #515f65;
    font-size: 40px;
    box-shadow: 0px 5px 5px rgb(195, 195, 195);
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #d1d1d1;
    margin-left: -10px;
    margin-top:20px
}

.section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 800px;
    height: 100%;
    position: relative;
}

.thirdText{
    width: 370px;
    margin-top: 50px;
    font-size: 22px;
   
    
}

@keyframes scroll {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
  }

@keyframes slide {
    0%{
        max-height: 0%;
    }
    100%{
        max-height:100%;
    }
}

.firstBlob{
    position: absolute;
    width: 1200px;
    top:0px;
    left:-500px;
    z-index: -10;
}

.secondBlob{
    position: absolute;
    width: 1400px;
    top:80px;
    right: -700px;
    transform: rotate(150deg);
}

.thirdBlob{
 position: absolute;
 width: 500px;
 right: 60px;
 top:300px
}

.fourthBlob{
    position: absolute;
    width: 1200px;
    top:0px;
    left:-300px;
}

.fifthBlob{
    position: absolute;
    width: 1200px;
    top:0px;
   right:-650px;
    transform: rotate(110deg);
}

.sixthBlob{
    position: absolute;
    width: 1300px;
    top:300px;
    right: -400px;
    transform: rotate(0deg);
    z-index: -1;

   

}

.seventhBlob{
    position:absolute;
width: 2000px;
top:3800px;
transform: rotate(180deg);
right: -800px;
   
}

.eightBlob{
    position:absolute;
    width: 1000px;
    top:900px;
    right:-280px;
    opacity: 0.3;
    z-index: -10;
    transform: rotate(330deg);
   
}

.bars{
}

.activeBar{
    height: 40px;
    width: 10px;
    background: #0088BC;
	background: linear-gradient(
		180deg,
		#0088BC 0%,
		#99CE6E 100%
	);
    border-radius: 10px;
    margin-top: 10px;
}

.inactiveBar{
    height: 40px;
    width: 10px;
    background-color: #D9D9D9;
    margin-top: 10px;
    border-radius: 10px;
}

.noDisp{
    opacity: 0.0001;
}



@media screen and  (max-height:1000px) {
    .downBut{margin-top: 0px;}
    .slideBut{margin-bottom: 10px};
    .secondSectionGreyTxt{
        font-size: 20px;
    }
    .secondSectionGreenTxt{
        font-size: 20px;
    }
    .fourthSectionTitle{
        font-size: 50px;
    }
    .firstSectionMainTxt{
        font-size: 50px;
    }

    .firstSectionSubTxt{
        font-size: 30px;
     
    }

    .colorLine{
        margin-top: 30px;
    }

    .butArr{
        font-size: 35px;
    }

    .thirdText{
        margin-top: 30px;
        font-size: 20px;
    }
    .fourthSectionItemList{
        margin-top: 20px;
        margin-left: 80px;
    }
    .lastLine{
        margin-top: 20px !important;
    }

}

@media screen and  (max-height:800px) {
    .secondSectionGreyTxt{
        font-size: 18px;
    }
    .secondSectionGreenTxt{
        font-size: 18px;
    }
    .fourthSectionTitle{
        font-size: 45px;
    }
    .firstSectionMainTxt{
        font-size: 45px;
    }

    .firstSectionSubTxt{
        font-size: 27px;
    }

    .colorLine{
        margin-top: 25px;
    }

    .butArr{
        font-size: 30px;
    }

    .thirdText{
        margin-top: 28px;
        font-size: 16px;
        width: 300px;
    }

    .fourthSectionItemList{
        margin-top: 20px;
        font-size: 20px;
        margin-left: 70px;
    }
    .lastLine{
        margin-top: 20px !important;
    }

}

@media screen and  (max-height:700px) {
    .secondSectionGreyTxt{
        font-size: 15px;
    }
    .secondSectionGreenTxt{
        font-size: 15px;
    }
    .fourthSectionTitle{
        font-size: 40px;
    }

    .firstSectionMainTxt{
        font-size: 40px;
        margin-top: -20px;
    }

    .firstSectionSubTxt{
        font-size: 25px;
        margin-top: 20px;
    }

    .colorLine{
        margin-top: 20px;
    }

    .butArr{
        font-size: 30px;
    }

    .thirdText{
        font-size: 14px;
    }

    .fourthSectionItemList{
        margin-top: 10px;
        font-size: 17px;
    }
    .lastLine{
        margin-top: 10px !important;
    }

}

@media screen and  (max-height:630px) {
    .secondSectionGreyTxt{
        font-size: 14px;
    }
    .secondSectionGreenTxt{
        font-size: 14px;
    }
    .fourthSectionTitle{
        font-size: 30px;
    }

    .colorLine{
        margin-top: 10px;
    }

    .butArr{
        font-size: 27px;
    }
    .thirdText{
        margin-top: 25px;
        font-size: 13px;
    }

    .fourthSectionItemList{
        margin-top: 10px;
        font-size: 13px;
        margin-left: 30px;
    }
    .lastLine{
        margin-top: 10px !important;
    }

}

@media screen and  (max-width:1850px) {
    .firstBlob{
        width: 1000px;
      
    }

    .secondBlob{
        width: 1200px;
    }
}

@media screen and  (max-width:1350px) {
    .firstBlob{
        width: 900px;
      
    }

    .secondBlob{
        width: 1100px;
    }
}

@media screen and  (max-width:1150px) {
.section{
    width: 700px;
}

.firstSectionMainTxt {
    font-size: 55px;
    margin-top: -8px;
}

}

@media screen and  (max-width:1050px) {
    .firstBlob{
        width: 800px;
        top:0px;
        left:-400px;
    }

    .secondBlob{
        width: 1000px;
        top:300px;
    right: -600px;
    }
}


@media screen and  (max-width:950px) {


.section{
width: 600px;
}

.secondBody{
    overflow-x: hidden;
}


.firstSectionMainTxt {
    font-size: 50px;
    margin-top: -8px;
}

.firstSectionSubTxt{
    font-size: 32px;
    margin-top: 20px;
}

.secondSectionGreyTxt{
    font-size: 20px;
    width: 500px; 
}
.secondSectionGreenTxt {
	
	font-size: 20px;
}

.fourthSectionTitle{
    font-size: 50px;
}


}

@media screen and  (max-width:750px) {


    .section{
    width: 500px;
    }

    .firstSectionMainTxt {
        font-size: 42px;
        margin-top: -15px;
    }
    
    .firstSectionSubTxt{
        font-size: 29px;
        margin-top: 15px;
    }

    .secondSectionGreyTxt{
        font-size: 15px;
        width: 400px; 
    }
    .secondSectionGreenTxt {
	
        font-size: 15px;
    }
    
    .fourthSectionTitle{
        font-size: 45px;
    }

    .colorLine{
        margin-top: 20px;
    }
   

}

@media screen and  (max-width:550px) {
    .firstBlob{
        opacity: 0.2;
    }

    .secondBlob{
        opacity: 0.2;
    }

    .section{
    width: 400px;
    }

    .firstSectionMainTxt {
        font-size: 35px;
        margin-top: -17px;
    }

    .secondSectionGreyTxt{
        font-size: 12px;
        width: 300px; 
    }

    .secondSectionGreenTxt {
	
        font-size: 12px;
    }
    
    .fourthSectionTitle{
        font-size: 35px;
    }

   

    .colorLine{
        margin-top: 10px;
    }

    .thirdText{
        font-size: 20px;
    }

    .fourthSectionItemList{
        font-size: 19px;
        margin-left: 60px;
    }

}

@media screen and  (max-width:450px) {


    .section{
    width: 300px;
    }
    .secondSectionGreyTxt{
        font-size: 12px;
        width: 250px; 
    }

    .firstSectionMainTxt {
        font-size: 26px;
        margin-top: -20px;
    }

    .firstSectionSubTxt{
        font-size: 20px;
        margin-top: 10px;
    }

    .thirdText{
        font-size: 18px;
        width: 280px;
    }
    .fourthSectionItemList{
        font-size: 15px;
        margin-left: 40px;
        margin-top: 20px;
    }

    .block{
        height:  calc( 100vh - 90px);
    }


}