

.table_elements{
    display: flex;
    width: 93%;
    justify-content:space-between;
    margin-top: 50px;
    margin-left:25px;
}



.next_but{
    margin-left:10px;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}

.next_but_dis{
    margin-left:10px;
    background-color:rgb(203, 213, 228) ;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}


.pager{
    display: flex;
    flex-direction: row;
    color:rgb(27,68,128);
    
    
   

}

.page_of_pages{
 color:rgb(27,68,128)
}

.page_buttons{
    border:none;
    color:rgb(27,68,128);
    font-size: large;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.page_buttons_selected{
    border:none;
    color:white;
    font-size: large;
    margin-left: 10px;
    background-color: rgb(27,68,128);
    border-radius: 5px;
}



button:hover{
    
    transform: scale(1.1);
}

.table_block{
    display: flex;
    justify-content: center;
    
    height: 400px;
    width:100%
    
}

.validated{
    color:white;
    background-color: rgba(55, 170, 60, 0.68);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height:25px;
    width: 70%;
}
.not_validated{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgb(168, 88, 82);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border-radius: 10px;
    height:25px;
    width: 70%;
   
}

.edit{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border-radius: 10px;
    height:25px;
    width:80px;
    border:none;
    font-size: x-small;
}

.editLink a{display: flex;
justify-content: center;
align-items: center;
text-decoration: none;

}

.deleteLink {
   
    background-color: rgb(45, 215, 45);
    display: flex;
    justify-content: center;
    
    }




.delete{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgb(211, 32, 32);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border-radius: 10px;
    height:25px;
    width:80px;
    border:none;
    font-size: x-small;
}

.table_block table{
    margin-top: 30px;
    display:block;
    max-height: 370px;
    width:95%;
    border-collapse: collapse; 
    overflow:auto;
}




  

.th{
    text-align: start;
    color:rgb(27,68,128);
    font-size: large;
    height:70px;
    width: 1%;
    
}

tr{
    border-bottom: 1px solid rgb(79,79,79);
    height: 60px;
    
   
}

td{
    font-size: x-small;
   
}


.listWBut{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rowLabel{
    margin-left: 10px;
}

@keyframes loading{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 1;
    }

    100%{
        opacity: 0%;
    }
}





  .ring
{
  
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
 
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:rgb(27,68,128);
  letter-spacing:4px;
  text-transform:uppercase;
  margin-top: 250px;
  margin-left: 50px;
  
 
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid rgb(27,68,128);
  border-right:3px solid rgb(27,68,128);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}


@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }

  
}

.centerTH{
    text-align: center;
}