.block{
    display: flex;
    flex-direction: row;
    height:90vh;
 
   
    
}

.menu{
    
    
    width: 100%;
    
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    
    justify-content: center;
    
}
.menu::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


  .tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   top: 200px;
   right: -25%;
   position: fixed;
}