.title{
    font-size: xx-large;
    color:rgb(27,68,128);
    margin-top: 40px;
}

.block{
    width:85%}


