.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc( 100vh - 210px);
}

.blobHolder{
  height: 150px;
  display: flex;
  align-items: end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.firstBlob{
  position: absolute;
  height: 1500px;
  left: -700px;
  top: -250px;
  opacity: 0.3;
}

.secondBlob{
  position: absolute;
  height: 2200px;
  right: -1300px;
  top: -110px;
  transform: rotate(-115deg);
  opacity: 0.3;
}


.card{
  width: 600px;
  height: 65px;
  border-radius: 40px 40px 0 0;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  z-index: 100;
}

.activeCard{
  background-color: white;
  color:#94C66B
}

.deactiveCard{
  background-color: #BFBDBD;
  color: white;
}

.infoHolder{
  width: 1210px;
  height: calc( 100% - 150px );
  overflow-y: auto;
}

.subCard{
  width: inherit;
  background-color: #f4f3f3;
  margin-top: 50px;
  height: 80px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.statusSQ{
  height: 50px;
  width: 50px;
  margin-left: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactiveSQ{
  background-color: #6a7d85;
}

.orangeSQ{
  background-color: rgb(255, 176, 30);
}

.activeSQ{
  background-color: #94C66B;
}

.blocked{
  color:white;
  font-size: 30px;
}

.noSubText{
  margin-left: 20px;
  font-size: 20px;
  color: #5d6c71;
  font-weight: bold;
}

.subBut{
  background-color: #94C66B;
  border-radius: 5px;
  border:none;
  height: 50px;
  color: white;
  margin-left: auto;
  margin-right: 20px;
  width: 200px;
}

.subText{
  font-weight: bold;
  font-size: 17px
}

.cost{
  font-size: 13px;
}

.subInput{
  display: none;
}

.stripeForm{
  margin-left: auto;
  margin-right: 20px;
}

.activeSubText{
 color:#94C66B;
 margin-left: 20px;
font-size: 20px;
font-weight: bold;

}

.cancelBut{
  background-color: #BFBDBD;
  border-radius: 5px;
  border:none;
  height: 50px;
  margin-left: auto;
  margin-right: 20px;
  width: 200px;
  color: white;
}


@media (max-width:1250px){
  .infoHolder{
    width: 1010px;
  }
  .card{
    width: 500px;
  }
}

@media (max-width:1050px){
  .infoHolder{
    width: 810px;
  }
  .card{
    width: 400px;
  }
}

@media (max-width:850px){
  .infoHolder{
    width: 610px;
  }
  .card{
    width: 300px;
  }

  .noSubText{
    margin-left: 20px;
    font-size: 17px;
  }
  .activeSubText{
   font-size: 17px;
   
   }
   .statusSQ{
    height: 40px;
    width: 40px;
   }

   .subBut{
    height: 50px;
    margin-right: 10px;
    width: 150px;
  }
  .cancelBut{
    width: 150px;
  }

  .subText{
    font-size: 15px
  }
  
  .cost{
    font-size: 12px;
  }
}

@media (max-width:650px){
  .infoHolder{
    width: 510px;
  }
  .card{
    width: 250px;
  }
  
}

@media (max-width:550px){
  .infoHolder{
    width: 410px;
  }
  .card{
    width: 200px;
    font-size: 17px;
    height: 50px;
  }

  .noSubText{
    margin-left: 15px;
    font-size: 13px;
  }
  .activeSubText{
   font-size: 13px;
   
   }
   

   .subBut{
    height: 45px;
    margin-right: 10px;
    width: 120px;
  }
  .cancelBut{
    width: 120px;
    height: 45px;
  }

  .subText{
    font-size: 15px
  }
  
  .cost{
    font-size: 12px;
  }
}

@media (max-width:450px){
  .infoHolder{
    width: 310px;
  }
  .card{
    width: 150px;
    font-size: 15px;
  }

  .noSubText{
    margin-left: 15px;
    font-size: 11px;
  }
  .activeSubText{
   font-size: 13px;
   
   }
   

   .subBut{
    height: 45px;
    margin-right: 10px;
    width: 100px;
  }
  .cancelBut{
    width: 100px;
    height: 40px;
    margin-right: 10px;
  }

  .subText{
    font-size: 12px
  }
  
  .cost{
    font-size: 10px;
  }
}

