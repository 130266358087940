.mainBlock{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.firstRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
}

.secondRow{
    display: flex;
    flex-direction: column;
    margin-left: 100px;
}

.email{
    
    margin-left: 30px;
    color:#737373;
    font-weight: bold;
    font-size: 18px;
}

.block{
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-left: 20px;
}

.adminBlock{
    background-color: #2ad0e3;
}

.userBlock{
    background-color: #a4a4a4
}

.moderatorBlock{
    background-color: #87ddbf;
}

.ambasadorBlock{
    background-color: #9a9cd7;
}

.icon{
    font-size: 30px;
    color: white;
}

.date{
    width: 33%;
}


.company{
   margin-left: auto;
   margin-right: 30px;
   display: flex;
   flex-direction: column;
   align-items: end;
}

.accountLink{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc( 100% - 2px);
    margin-left: 1px;
    border-radius: 5px;
    height: 130px;
    background-color: #f3f1f1;
    text-decoration: none;
    color:black
}

.userTxt{
    color:#a4a4a4;
}

.moderatorTxt{
    color:#87ddbf;
}

.adminTxt{
    color:#2ad0e3;
}

.ambasadorTxt{
    color: #9a9cd7;
}

.dateLabel{
    color: #979797;
}