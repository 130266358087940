.block{
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    
}

.registerBox{
 
    
    
}

.NanoNams{
    margin-left: 10px;
}
.downBar{
    background-color: rgb(82, 95, 102);
    height:80px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.icon{
    position: fixed;
    left: 0;
    top:0;
    width: 200px;
    
overflow:hidden;
    
}

.iconImage
{
float:left,top ;
width:100%;
position:relative;
height:auto;
margin-left:-20% ;
margin-top:-20%;
}

.logBox{
    height:600px;
    width:500px;
    background-color: #525f66;
    border-radius: 20px;
    color:white;
    margin-top:20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
}

.realResetBox{
    
    height:600px;
    width:500px;
    background-color: #525f66;
    border-radius: 20px;
    color:white;
    position: relative;
    margin-top:20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);

}

.iconTitle{
    
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.title{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: x-large;
    margin-top: 1%;
    margin-left: 2%;
}

.boxMeat{
    margin-top:2%;
    display: flex;
    flex-direction: row;
   width: 400px;
}

.register{
    font-size: large;
    text-align: center;
}

.registerBlock{
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
    width:100%; 
}

.logIn{
    margin-left: 5px;
    color: #99ce6d;
}

.realResetBlock{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
    width:100%; 
}

.logo{
    margin-top:20px;
    margin-left: calc( 50% - 115px );
    width:200px
}

.logFormElement{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 400px;
}

.email{
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    width:100%;
    font-size: 14px;
    padding-left: 10px;
}

.resetEmail{
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    width:100%;
    font-size: 14px;
    padding-left: 10px;
}

.password{
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    width:100%;
    font-size: 14px;
    padding-left: 10px;
}
.logForm{

}
.logFormElement{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  
}

.buttons{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width:100%;
    justify-content: space-between;
}

.registerButton{
    width:40%;
    height: 40px;
    background-color: rgb(132, 179, 116);
    border: none;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    
}

.password_reset_button{
    width: 100%;
    height: 40px;
    margin-right: 7%;
    background-color: rgb(56, 111, 179);
    border: none;
    border-radius: 10px;
    color: white;
    
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    

}

.regText{
    overflow-wrap: break-word;
    width: 160px;
}
.regTextPas{
    overflow-wrap: break-word;
    width: 180px;
}

.password_reset{
    margin-left:5px;
    height: 40px;

    display: flex;
    align-items: end;
}

.iconSecond{
    position: fixed;
    left: 100%;
    bottom: 20%;
    width: 40%;
    
    
    overflow:hidden;
    position: relative;
    
}

.iconSecondForgot{
    display: none;
    position: fixed;
    left: 100%;
    bottom: 50%;
    width: 60%;
    height: auto;
    position: relative;
    overflow:hidden;
    
    
}

.iconSecondImage{
    float:left,top ;
width:100%;
position:relative;
height:auto;
margin-left:-20% ;
margin-top:-10%;
}

.errors{
    margin-top:10px;
    font-size: small;
    display: flex;
    justify-content: center;
    text-align: center;
}

.forgotBox{
    height:400px;
    width:500px;
    background-color: #525f66;
    border-radius: 20px;
    color:white;
    position:relative;
   
}

.forgotBlock{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 60%;
    width:90%;
    margin-left: 2%;
    
    
    
}

.forgotBoxMeat{
    
    margin-top:5%;
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: start;
}

.forgotEmailInput{
    
    width: 100%;
    height: 30px;
    padding-left: 10px;
}

.forgotBut{
    background-color: rgb(132, 179, 116);
    
    border:none;
    border-radius: 10px;
    color:white;
    height: 30px;
    width: 80px;
    margin-left: 40%;
    margin-top: 20px;
}

.forgotError{
    font-size: small;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    
    
}

.forgotFormBox{
   
    width: 100%;
    display: flex;
    justify-content: center;
   
}


.registerForgotEmail{
   
}

.registerForgotBox{
   display: flex;
   flex-direction: column;
    width: 100%;
    align-items: center;
}

.forgotForm{
  
    height: 80%;
    
    width: 80%;
    
}

.resetBox{
    height:50%;
    width:20%;
    background-color: #525f66;
    border-radius: 20px;
    color:white;
}
.resetBlock{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
    width:90%;
    margin-left: 5%;   
}

.resetForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-size: smaller;
}

.resetInput{
    width: 100%;
    height: 25px;
    margin-top: 10px;
}

.resetLabel{
    margin-top: 10px;
}

.resetButton{
    margin-top: 20px;
    width: 70px;
    height: 30px;
    border: none;
    border-radius: 10px;
    background-color:rgb(132, 179, 116);
    color:white;
    margin-left: 30%;
}

.realResetButton{
    margin-top: 20px;
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 10px;
    background-color:rgb(132, 179, 116);
    color:white;
    margin-left: calc( 50% - 40px );
}

.resetBoxMeat{
    display: flex;
    flex-direction: column;
}

.resetError{
  
    width: 160%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
}

.tlo{
    height:1100px;
     width:1100px;
    transform: rotate(260deg);
    opacity: 0.2;
    z-index: -1;
    top: 0px;
    right: -15%;
    position: fixed;
}

.checkRow{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-left: 2px;
    margin-top: 5px;
}

.accepts{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    font-size: 12px;
    
}

.checkLabel{
    margin-left: 5px;
}

input[type="checkbox"] {

    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #007a7e;
    outline: none;
    cursor: pointer;
  } 

 

  .check{
    background-color: rgb(132, 179, 116);
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #007a7e;
    outline: none;
    cursor: pointer;
  }

  .checkError{
    

    border: 1px solid red !important;
    box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 0.7);


  }
  




  .check::before {
    content: "\2713";
    font-size: 15px;
    color: #fff;
    position: absolute;
    left: 2px;
    top: -2px;
  }

  .pdfLink{
    color:rgb(132, 179, 116);
    text-decoration: underline;
  }
  .logForm{
    
    width: 100%;
  }

@media(max-width:600px){
    .logBox{
        width: 400px;
        height:500px
    }

    .register{
        margin-top: 150px;
    }
    .logFormElement{
        width: 80%;
      
        margin-left: 10%;
    }
    .checkRow{
      
        width: 80%;
        margin-left: 10%;
    }
    .realResetBox{
        width: 400px;
        height:500px
    }

    .forgotBox{
        width: 400px;
        height:200px
    }

    .logo{
        margin-top: 0px;
        width: 100px;
        margin-left: calc( 50% - 58px );
        display: none;
    }

    .email{
        width: 100%;
    }
    .password{
        width: 100%;
    }

    .buttons{
        width: 80%;
        margin-left: 10%;
    }

    .forgotBut{
        margin-left: 40%;
    }


    .resetEmail{
        width: 100%;
    }

    .realResetButton{
      
    }
    .resetError{
        width: 100%;
    }

    
   
}


@media(max-width:450px){
    .boxMeat{
        margin-top: 0px;
        width: 100%;
    }
    .register{
        margin-top: 170px;
    }
    .logBox{
        width: 300px;
        height: 480px;
    }

  .errors{
    font-size:10px;

  }

    .realResetBox{
        width: 300px;
        height: 530px;
    }

    .realResetBlock{
        margin-top: 60px;
    }

    .resetError{
        width: 110%;
    }

    .forgotBox{
        width: 300px;
        height:210px
    }

    .forgotBut{
        margin-left: 35%;
    }

    .logo{
       display: none;
    }

    .NanoNams{
        font-size: large;
    }



   .regText{
    
   margin-left: -18px;
   }
   .logForm{
    width: 100%;
   }

  
   .password_reset_button{
    width: 120px;
   }

   .checkRow{
    align-items: center;
   }


}