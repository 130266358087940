.block{
  width: 100%;
    display: flex;
    flex-direction: column;
    
    
}

.window{
    width: 100%
    
}

.editing{
    color:rgb(27,68,128);
    font-size: xx-large;
    margin-top: 50px;
}

.editingNam{
    color:rgb(27,68,128);
    font-size: x-large;
    margin-top: 80px;
}

.editingNam2{
    color:rgb(27,68,128);
    font-size: x-large;
    margin-top: 220px;
}

.indent{
    margin-left: 20px;
    margin-top:20px
}


.rowForm{
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;

}

.metaInput{
    width: 75%;
}



#desc{
    height:100px;
    width: 400px;

    background-color: rgb(255, 255, 255);
    border:solid;
    border-color: rgb(197, 197, 197);
    border-radius: 5px;
    border-width: 1px;
    padding-left: 10px;
   
}

#ref{
    width: 300px;
    height: 30px;
}

#pub{
    height: 30px;
    width: 300px;
}

.label{
    color:rgb(27,68,128);
    font-size:large
}

.add{
    height: 40px;
    width: 80px;
    background-color: #525f66;
    color:white;
    border:none;
    border-radius: 5px;
    
    margin-top: auto;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
    
    
    
}

.break{
    height: 50px;
}

@media (max-width:1400px){
    .editingNam2{margin-top: 650px;}
}

.button2{
    display: none;
    margin-top: 10px;
    
    width: 490px;
}

@media (max-width:1400px){
   
    #desc{
        width: 350px;
    }
    .button1{
        display: none;
    }

    .button2{
        display:flex;
    }

    .add{
        margin-left: auto;
    }

    .addH{
        margin-left: auto;
    }
}