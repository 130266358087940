.block{
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    width: 900px;
    margin-left: calc( 50% - 450px );
}

.pageTitle{
    font-size: 33px;
    font-weight: bold;
    color:#737373;
    margin-top: 100px;
}

.table{
    margin-top: 20px;
}

.break{
    height: 50px;

}

.labels{
    display: flex;
    flex-direction: row;
    width: 90%;
    font-weight: bold;
    margin-top: 20px;
}

.email{
    width: 30%;
    margin-left: 20px;
}

.date{
    width: 33% ;
}


.company{
    width: 33% ;
}

.buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.nextBut{
    height: 45px;
    width: 90px;
    border:solid;
    border-width: 1px;
    color: white;
    background-color: #515f66;
    border-radius: 10px;
    margin-left: 10px;
}

.prevBut{
    height: 45px;
    width: 90px;
    border:solid;
    border-width: 1px;
    color: white;
    background-color: #515f66;
    border-radius: 10px;
}

.deactivated{
    background-color: rgb(228, 228, 228)
}


.pager{
    width:150px;
    margin-left: auto;
    margin-right: 15px;
}

.filters{
    width: 100%;
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: end;
}

.firstDropdown{
    width: 300px;
    margin-left: auto;
}

.secondDropdown{
    margin-left: 50px;
    width: 200px;
}


