.row{
    display: flex;
    flex-direction: row;
    margin-top:10px;
    align-items: center;

   
}

.block{
}

.desc{
    margin-top: 50px;
    font-size: x-large;
    font-weight: bold;
}

.descText{
    margin-top: 20px;
    display: block;
    overflow: auto;

    height:150px;
    
}

.details{
    font-size: x-large;
    font-weight: bold;
   
}

.subTitle{
    font-weight: bold;
    width: 100px;
}

.subTitle2{
    font-weight: bold;
    width: 300px;
}

.qMark{
    color:#525f66;
    font-size:20px;
    background:rgba(0, 0, 0, 0)
}

.genElement{
    margin-left: 50px;
    width:150px;
    overflow-wrap: break-word;
}

.genElementReg{
    margin-left: 50px;
    display: block;
    
    width:150px;
    
    max-height: 70px;
    overflow: auto;
}

.genElementType{
    margin-left: 50px;
    display: block;
    
    width:200px;
    
    max-height: 70px;
    overflow: auto;
}


.breakAop{
    margin-top: 50px;
}

.question{
    margin-left:5px;
}

.Qbutton{
    background-color:rgba(0, 0, 0, 0);
    border:none;
}

.reference{
    margin-left: 5px;
    width: 75%;
    overflow-wrap: break-word;
}


.backButton{
    margin-top:20px;
    
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border:none;
    height: 45px;
    width: 100px;
    border-radius: 10px;
    
    text-align: center;
}
.back{
    color:white;
}

.backLink{
    width: 100px;
    margin-left:80%;
}

.descLink{
    overflow-wrap: break-word;
    width:120px;
    background-color: red;
}


@media (max-width:360px){
    .subTitle2
    {width: 200px;}
}

@media (min-width:1700px){
    .block{
        display: flex;
        flex-direction: row;
    }
}