.block{
    width: 100%;
    background-color: rgb(33, 33, 33);
    opacity: 1;
    color: white;
    display: flex;
    flex-direction: column;
    height: 150px;
    bottom: 0;
    position: fixed;
    z-index: 2;
    align-items: center;
    
}

.blockClosed{
    display: none;
}

.accept{
    background-color: 
    #99ce6d;
    color: white;
    border: none;
    border-radius: 10px;
    height: 45px;
    width: 300px;
}

.nec{
    background-color: 
    #99ce6d;
    color: white;
    border: none;
    border-radius: 10px;
    height: 45px;
    width: 300px;
}

.header{
    margin-top: 10px;
    font-size: 20px;
}

.desc{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.buttonRow{
    width: 650px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    margin-top: 10px;
}


@media screen and (max-width:1150px){
    .block{
        height: 170px;
    }
}

@media screen and (max-width:700px){
    .block{
        height: 230px;
    }

    .buttonRow{
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: space-around;
       align-items: center;
    }
}

@media screen and (max-width:610px){
    .block{
        height: 245px;
    }

   
}

@media screen and (max-width:450px){
    .block{
        height: 260px;
    }

   
}