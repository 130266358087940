.block{
    margin-left: 20px;
    margin-right: 10px;
    z-index: 1000;
}

.but{
    background-color: #525f66;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); 
    border:none;
    height: 50px;
    width:60px
}

.but:hover{
    border:solid;
    border-radius: 5px;
    background-color: #525f66;
    border-color: #48545a;
}


.link{
    color:black;
    text-decoration: none;
}

.manage{
    color:black;
    text-decoration: none;
}

.link:hover{
    color:black
}

.horizontal{
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: black;
    height: 1px;
    width: 80%;
    margin-left: 10%;
}

.icon{
    color: white;
    font-size: 35px;
}

.extraLink{
    display: none;
}

@media screen and(max-width:600px){
    .icon{
        font-size: 30px;
    }
}

@media (max-width:500px){
    .extraLink{
        display: block;
    }
}

/* @media (max-width:720px){
    .manage{
        display: none!important;
    }
} */