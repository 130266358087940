.head {
    color: #525f66;
    
    font-weight: bold;
    margin-bottom: 2px;
}

.newsText {
    margin-left: 20px;
    text-align: justify;
}

.icons {
    font-size: 20px;
    color: #525f66;
}

.outer {
    position: relative;
  }

.verticalC {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.icnBtn {
    background-color: transparent;
    border: none;
    margin-right: 15px;
    padding: 0;
}

.icnBtn:hover {
    background-color: transparent;
    border: none;
}

.icnBtn:focus{
    background-color:transparent;
}


.icon{
    background-color: #525f66;
    color: #525f66;
}

.row{
    width: 90%;
}

@media (max-width:1000px){
    .newsText{
        margin-left: 0px;
    }
    .row{
        margin-left: 5%;
    }
}