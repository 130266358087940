.block{
    margin-top: 20px;
    margin-left: 20px;
   
}

.row{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    
    width: 1100px;
   
}

.lastRow{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    
    width: 1100px;
   
}

.rowForm{
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;

}

.lastRowForm{
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;

}

.metaInput{
    width: 75%;
}



#desc{
    height:100px;
    width: 400px;

    background-color: rgb(255, 255, 255);
    border:solid;
    border-color: rgb(197, 197, 197);
    border-radius: 5px;
    border-width: 1px;
    padding-left: 10px;
   
}

#ref{
    width: 350px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
}

#pub{
    height: 45px;
    width: 350px;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
}

input{
    background-color: rgb(238,238,238);
    border:solid;
    border-color: rgb(197, 197, 197);
    border-radius: 5px;
    border-width: 1px;
}

.label{
    font-size: large;
    color:rgb(27,68,128);
}

.add{
    height: 40px;
    width: 80px;
    background-color: #525f66;
    color:white;
    border:none;
    border-radius: 5px;
}

.addH{
    height: 40px;
    width: 80px;
    background-color: rgb(160, 160, 160);
    color:white;
    border:none;
    border-radius: 5px;
}

.button2{
    display: none;
    margin-top: 10px;
    
    width: 490px;
}

@media (max-width:1400px){
    

    #desc{
        width: 350px;
    }
    .button1{
        display: none;
    }

    .button2{
        display:flex;
    }

    .add{
        margin-left: auto;
    }

    .addH{
        margin-left: auto;
    }
}