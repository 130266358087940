.block{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    align-items: center;
    height: 45px;
    width: 100%;
    position: relative;
  
}

.label{
    color:#616161;
    font-size: 20px;
    width: 200px;
}

.input{
    height: 45px ;
    width: calc( 100% - 220px );
    background-color: white;
    padding-left: 10px;
    border: 2px solid rgb(170, 170, 170);
    border-radius: 10px;
}

.input:focus{
    border: 2px solid #98cf6d;;
    outline: none;
}

.questionButt{
    margin-left: 5px;
    border: none;
    background-color: inherit;  
}

.labelQuestionButt{
    margin-left: 0px;
    margin-bottom: 0px;
    display: none;
}

.questionIcon{
    font-size: 24px;
    opacity: 0.2;
    pointer-events: none;
   
}

.labelQuestionIcon{
    font-size: 20px;
    margin-bottom: 7px;
}

.modal{
    position: absolute;
    min-width: 300px;
    max-width: 600px;
    left:-150px;
    background-color: rgb(220, 220, 220);
    border-radius: 10px;
    max-height: 230px;
    top:100%;
    left: calc( 100% - 200px);
    opacity:1;
    z-index: 100;
    text-align: center;
}

.labelModal{
    display: none;
}

.modalText{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width:650px){
    .questionButt{
        display: none;
    }
    .labelQuestionButt{
        display: block;
    }
    .block{
        flex-direction: column;
        align-items: start;
        height: 70px;
    }
    .label{
        display: flex;
        flex-direction: row;
        width: 100%;
       font-size: 15px;
    }

    .input{
        height: 70px;
        width: calc( 100%  );
        font-size: 12px;
    }

    .modal{
      
        min-width: 90%;
        background-color: rgb(220, 220, 220);
        border-radius: 10px;
        max-height: 330px;
        top:100%;
        left: calc( 0%);
        opacity:1;
        z-index: 100;
        text-align: center;
        display: none;
    }
    .labelModal{
        display:block
    }
}