.logo{
    margin-top: 7px;
   
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;


}

.block{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    
    width:auto;
}




.logoSize{
   
     width:160px
}

@media  (max-width: 1020px) {

.logoSize{
 
    width:100px;
}



    
}


@media  (max-width: 950px) {

    .logoSize{
     
        width:100px;
    }
    
    
  
        
    }

@media  (max-width: 670px) {

        .block{
            display: none;
        }
        
        
      
        }

@media  (max-width: 410px) {

            .logoSize{
           
                width:70px;
            }
            
            
           
                
            }

@media  (max-width: 360px) {

                .logoSize{
            
                    width:60px;
                }
                
                
             
                    
                }