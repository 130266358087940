.mypanel > [class~="p-multiselect-triger"] {
    background-color: #e87b07!important;
    color: #ffffff;
    width: 10px;
    
  }
  
  
  .mypanel > [class~="p-multiselect-label-container"] {
    
    width: 250px;
    
    
    
  }
  
  
  
  
  .mypanel > [class~=p-multiselect-filter-container] {
    background-color: #07c4e8!important;
      color: #ffffff;
  
   
    
  }
  
  
  @media (max-width:600px){
    .mypanel{width: 240px;}
  }
  
  @media (max-width:500px){
    .mypanel{width: 200px;}
  }
  
  @media (max-width:350px){
    .mypanel{width: 170px;
    }
  
  }