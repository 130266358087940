.navbar{
    background-color: #525f66;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.2);
    height:110px;
    display:flex;
    flex-direction: row;
    align-items: center;
    
}

.line{
    height: 80px;
    background-color: rgb(255, 255, 255);
   margin-left: 10px;
    width: 2px;
}


.secondLine{
    height: 80px;
    background-color: rgb(255, 255, 255);
    margin-left: 20px;
    width: 2px;
    
}

@media (max-width:850px){
    .line{display: none;}
    .secondLine{display:none}
}

@media (max-width:450px){
    .navbar{
        height: 90px;
    }
}