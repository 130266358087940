.menuBlock{
    width: 100%;
   
    overflow: auto;
}

.scrollSlave{
    margin-left: 10%
}

.menuBlock::-webkit-scrollbar {
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.title{
    font-size: xx-large;
    color:rgb(27,68,128);
    margin-top: 40px;
    
}

.subtitle{
    margin-top: 50px;
    font-size: 30px ;
    color:#616161;
    font-weight: bold;
}

.subtitle2{
    margin-top: 50px;
    font-size: x-large;
    color:rgb(27,68,128);
}

.break{
    height: 50px;
}

.metaBreak{
    height: 30px;
}

.nameRow{
}

.filler{
    height: 52px;
    opacity: 0;
}

.mainForm{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    margin-top: 30px;
    background-color: #F7F7F7;
    width: 90%;
    height: 450px;
}

.firstColumn{
    margin-bottom: 10px;
    width: calc( 50% - 100px );
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
}

.secondColumn{
    width: calc( 50% - 100px );
    margin-bottom: 10px;
    margin-right: 50px;
    margin-left: 50px;
}

.metaData{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7F7F7;
    width: 90%;
    border-radius: 10px;
    margin-top: 30px;
}

.metaEl{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px;
}
.metaRow{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    width: calc( 50% - 100px );
    margin-left: 50px;
    margin-right: 50px;
    position: relative;
   
}
.metaRow2{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    width: calc( 50% - 100px );
    margin-right: 50px;
    margin-left: 50px;
    position: relative;

}

.metaRowDesc{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: start;
    width: calc( 100% - 100px );
    position: relative;
   
}

.label{
    color:#616161;
    font-size: 20px;
    width: 200px;
}

.input{
    height: 45px ;
    width: calc( 100% - 220px );
    background-color: white;
    padding-left: 10px;
    border: 2px solid rgb(170, 170, 170);
    border-radius: 10px;
}

.input:focus{
    border: 2px solid #98cf6d;;
    outline: none;
}


.textarea{
    height: 100px;
    width: calc( 100% - 200px );
    
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(170, 170, 170);
    border-radius: 10px;
    padding-left: 10px;
}

.textarea:focus{
    border: 2px solid #98cf6d;;
    outline: none;
}


.sendBut{
    height: 47px;
    width: 120px;
    background-color: #717a7e;
    color:white;
    border:none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    margin-left: calc( 50% - 60px );
    margin-top:30px
}

.blobHolder{
    height: 220px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .firstBlob{
    position: absolute;
    height: 1200px;
    left: -500px;
    top: -250px;
    opacity: 0.6;
  }
  
  .secondBlob{
    position: absolute;
    height: 1800px;
    right: -1300px;
    top: -110px;
    transform: rotate(-115deg);
    opacity: 0.6;
  }

  .blobText{
    position: absolute;
    top:70px;
    left: 10%;
    color:white;

  }

  .blobTitle{
    font-size: 40px;
    font-weight: bold;
  }

  .blobSubtitles{
    font-size: 20px;
  }

  .questionButt{
    margin-left: 5px;
    border: none;
    background-color: inherit;
}

.questionIcon{
    font-size: 24px;
    opacity: 0.2;
    pointer-events: none;
}

.modal{
    position: absolute;
    min-width: 300px;
    max-width: 600px;
    left:-150px;
    background-color: rgb(220, 220, 220);
    border-radius: 10px;
    max-height: 230px;
    top:100%;
    left: calc( 100% - 200px);
    opacity:1;
    z-index: 100;
    text-align: center;
}

.areaModal{
   top: 30px;
}

.modalText{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.labelModal{
    display: none;
}
.labelQuestionIcon{
    font-size: 20px;
    margin-bottom: 7px;
}

.labelQuestionButt{
    margin-left: 0px;
    margin-bottom: 0px;
    display: none;
}

.modalBlure {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
  }
  .blure{
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
  .noDisp{
    display: none;
  }
.subModalContent{
    width: inherit;
}
.subModalBox {
    /* background-color: transparent; */
    background-color: white;
    margin: 120px auto; 
    /* padding: 20px; */
    border: 2px solid rgba(209, 209, 209, 0.5);
    border-radius: 10px;
    width: 400px;
    height:400px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(40px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }
  .middleModal{
    height:300px;
    width: 600px;
  }

  .smallModal{
    height: 180px;

  }
.subModalElements{
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
  }

  .loginBar{
    background-color: #98cf6d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0 ;
    height: 70px;
  }
  .checkMark{
    color: white;
    font-size: 40px;
  }
  .dearUser{
    font-weight: bold;
    color: #525f66;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    margin-top: 10px;
    font-size: 20px;
  }
  .authText{
  
    font-size: 20px;
    width: calc( 100% - 30px);
    margin-left: 5px;
    text-align: center;
  }
  
  .authRow{
    display: flex;
    align-items: start;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px;
    
  }
  .buttonRow{
    width: calc( 100% - 20px );
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
  }
  .takeBack{
    background-color: #9d9d9dab;
    height: 40px;
    width: 150px;
    border:none;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 20px;
  }
  
  .takeBack:hover{
    border:solid;
    border-width: 1px;;
    border-color: #525f66;
    
  }

  .addNam{
    margin-left: 20px;
    height: 40px;
    width: 150px;
    border:none;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    background-color: #98cf6d;
  }
  
  .addNam:hover{
    border:solid;
    border-width: 1px;;
    border-color: #327031;
  }
  .resend{
    color:rgb(38, 107, 255)
  }
  .resend:hover{
    cursor: pointer;
  }

@media (max-width:1400px){
    .subtitle2{margin-top: 650px;}
}

@media (max-width:1350px){
   
    .mainForm{
        flex-direction: column;
        align-items: start;
        height: auto;
    }
    
    .filler{
        display: none;
    }
    .firstColumn{
        margin-bottom: 10px;
        width: calc( 100% - 75px );
        
    }
    .secondColumn{
        margin-bottom: 10px;
        width: calc( 100% - 75px );
        
    }

    .metaEl{
        flex-direction: column;
    }

    .metaRow{
        width: calc( 100% - 75px );
    }

    .metaRow2{
        width: calc( 100% - 75px );
    }

    .metaRowDesc{
        margin-left: 50px;
        width: calc( 100% - 75px );
    }

  
}


@media (max-width:700px){
    .questionButt{
        display: none;
    }
    .labelQuestionButt{
        display: block;
    }
    .metaRow{
        flex-direction: column;
        align-items: start;
        height: 70px;
    }
    .metaRow2{
        flex-direction: column;
        align-items: start;
        height: 70px;
    }

    .metaRowDesc{
        flex-direction: column;
        align-items: start;
        margin-top: 0px;
    }
    .label{
        display: flex;
        flex-direction: row;
        width: 100%;
       font-size: 15px;
    }

    .input{
        height: 70px;
        width: calc( 100%  );
        font-size: 12px;
    }

    .textarea{
        width: calc( 100%  );
        font-size: 12px;
    }

    .modal{
      
        min-width: 90%;
        background-color: rgb(220, 220, 220);
        border-radius: 10px;
        max-height: 330px;
        top:100%;
        left: calc( 0%);
        opacity:1;
        z-index: 100;
        text-align: center;
        display: none;
    }
    .labelModal{
        display:block
    }

   

    .blobTitle{
        font-size: 32px;
    }
    .blobSubtitles{
        font-size: 15px;
    }

    .blobHolder{
        height: 180px;
    }

    .subtitle{
        font-size: 25px ;
    }
}

@media (max-width:530px){
    .blobText{
        top:20px
    }
    .blobTitle{
        font-size: 18px;
    }
    .blobSubtitles{
        font-size: 10px;
        font-weight: bold;
    }
    .blobHolder{
        height: 100px;
    }
    .subtitle{
        font-size: 18px ;
    }
}

@media (max-width:430px){

    .blobSubtitles{
        font-size: 10px;
    }
    .blobTitle{
        font-size: 17px;
    }

    .subtitle{
        font-size: 17px ;
    }
   
}

@media (max-width:400px){
    .blobSubtitles{
        font-size: 10px;
    }
}

@media (max-width:370px){
    .blobSubtitles{
        font-size: 9px;
    }
}