.block{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header{
    font-weight: bold;
    font-size: 20px;
}
.icon{
    width: 120px;
    height: 120px;
    margin-top: 20px;
}

.confirm{
    color: #7cb23fab;
}
.fhBlock{
    margin-top: -150px
}