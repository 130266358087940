.row{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    width: 600px;
    justify-content: space-between;
}

.rowBut{
    display: flex;
    flex-direction: row;
    align-items: center;
}

button.rowBut{
    background-color: white;
    border:none;
    color:rgb(27,68,128);
    font-size: large;
}

.name{
    margin-left: 5px;
}

.guidance{
    margin-left: 5px;
    inline-size: 100px; 
    overflow-wrap: break-word;
    
}

.organ{
    inline-size: 70px; 
    overflow-wrap: break-word;
    margin-left: 5px;
}


.stage{
    inline-size: 130px; 
    overflow-wrap: break-word;
    margin-left: 5px;
}


.buttons{
    margin-top: 10px;
    width: 610px;
    display: flex;
    flex-direction: row;
    justify-content: end;

}

.addBut{
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border:none;
    color:white;
    margin-right: 10px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
}


.blockedEdit{
    background-color: rgb(221, 221, 221);
    border:none;
    color:rgb(0, 0, 0);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    margin-right: 10px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
}