.block{
    display: flex;
    flex-direction: row;
    height:100vh;
   
}

.menu{
    
    
    width: 100%;
    
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 20px;
    justify-content: center;
   
}

.tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   top: 200px;
   right: -25%;
   position: fixed;
}