button:hover{
    transform: scale(1)
}


.item{
    width: 100%;
}

.block{
    display: flex;
    flex-direction:  column;
    justify-content: space-between;
    margin-top: 50px;
}

.row{
    display: flex;
    flex: row;
    justify-content: space-between;
    margin-top: 20px;
 
}

.title{
    font-weight: bold;
    font-size: 18px;
    color:#515F65;
}

.adressList{
    color:#515F65;
}

.desc{
    margin-top: 20px;
    color:#515F65;
}

.link{
    word-wrap: break-word;
}

.accordBody{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.provInfo{
    max-width: 50%;
    margin-right: 10px;
}

.logo{
    width: 100px;
}

.adressInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width:1000px){
    .accordBody{
        flex-direction: column;
        justify-content: start;
    }
    .provInfo{
        max-width: 100%;
    }
}