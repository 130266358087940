.block{
    height: 100vh;
    width:220px;
    background-color: #525f66;
    margin-top:1px;
    display: flex;
    justify-content: start;
    
    flex-direction: column;
}



.text{
    margin-left: 20px;
}

.menu{
    margin-top: 130px;
    color:white;
    margin-left: 10%;
    font-size: xx-large;
}

.line{
    background-color: white;
    width: 90%;
    height: 1px;
    margin-top: 5px;
    margin-left: 10%;
}

.manage{
    display: flex;
    flex-direction: row;
    color:rgb(231, 231, 231);
    font-size: large;
    font-weight: 300;
    margin-top: 10px;
    margin-left:10%;
    font-weight: bold;
}

.selected{
    color:#94C66B;
    margin-left: 5px;
    font-weight: bold;
}

.serviceProviders{
    display: flex;
    flex-direction: column;
    color:rgb(231, 231, 231);
    font-size: large;
    font-weight: 300;
    margin-top: 10px;
    margin-left:10%;
    font-weight: bold;
    align-items: start;
}

.chemistryIcon{
    font-size: 40px;
}

a.add{
    color:rgb(231, 231, 231);
    text-decoration: none;
}

.add:hover{
    color:inherit
}

a.manage{
    text-decoration: none;
}

.manageText{
    margin-left: 50
}

.manageText:hover{

    color: white
}

.sublist{
    color:rgb(231, 231, 231);
    margin-left: 13%;
    margin-top: 0px;
}

.add{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
}

.addText{
    margin-left: 5px;
    font-weight: bold;
}

.circle{
    font-weight: bold;
    
}

.interline{margin-top: -5px;}