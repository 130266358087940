.block{
    
    margin-top: 50px;
    width:80%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    color:#515F65;
}

.name{
    font-size: xx-large;
    color:rgb(79,79,79);
}

.rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.namStatus{
    width: 50%;
    min-width: 470px;

}

.general{
    width: 50%;
  
    margin-left: 10px;
    
}

.desc{
  margin-top: 50px;
  font-size: x-large;
  font-weight: bold;
}

.descText{
  margin-top: 20px;
  max-height: 200px;
  
  overflow-y: auto;

}
.linkRow{
    display: flex;
    flex-direction: column;
    margin-top:10px;
    align-items: start;
  
   
  }

  .reference{
    margin-top: 6px;
    overflow-wrap: break-word;
    width: calc(100% - 45px);
   
  }

  .subTitle{
    width: 65px;
    margin-top: 10px;
  }
  
  
  .backButton{
    margin-top:20px;
    
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border:none;
    height: 45px;
    width: 100px;
    border-radius: 10px;
    color:white;
    text-align: center;
    margin-left: auto;
  }
  .linker{

    width:100%;
   max-height: 100px;
  
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    
  }
  .backLink{
    width: 100%;
    
    display: flex;
  }
  .space{
    height: 10px;
    width: 100%;
  }