.mainHeader{
    font-size: 32px;
    margin-top: 20px;
    margin-left: 20px;
}

.header{
    font-size: 24px;
    margin-top: 30px;
    margin-left: 20px;
}

.description{
    margin-left: 20px;
    margin-top: 10px;
    width: calc( 100% - 40px );
}

.cookieRow{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 20px;
}

.cookieTitle{
    font-weight: bold;
    margin-left: 5px;
    min-width: 150px;
}

.cookieDesc{
   
}

.endSpace{
    width: 100%;
    height: 50px;
}