.wrapDiv {
    display: flex;
    align-items: center;
}

.label {
    margin-bottom: 0;
    margin-right: 10px;
    color: #525f66;
    font-weight: bold;
}

.txt {
    color: #525f66;
    padding-bottom: 20px;
}

.btns {
    justify-content: center;
}

.buttonCancel {
    background-color:#525f66 !important;
    border: none !important;
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-weight: bold !important;
}

.buttonSave {
    background-color:#8eca6c !important;
    border: none !important;
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-weight: bold !important;
}

.area {
    height: 200px;
}

.field {
    max-width: 300px;
}