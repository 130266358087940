.wrap {
    padding-top: 50px;
    width: 80%;
    margin: auto;
   
    
    overflow: auto;
}



.car {
    padding-bottom: 30px;
    
    
}

.carImg1 {
    height:calc(80vw*0.48);
    
}

/* .last {
    margin-bottom: 28%;
} */

.hr {
    border: none;
    height: 1px;
    background-color:#fff;
    opacity: 0.7;
    margin-top: 8px;
}

.left {
    text-align: left;
    margin-top: 10px;
}

.leftLatest {
    text-align: left;
    font-size: xx-large;
    font-weight: bold;
}

.leftTitle {
    text-align: left;
    margin-top: 10px;
    font-size: x-large;
    font-weight: bold;
}

.capt {
    top: 1.5rem;
    overflow-y:auto;
}

::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px rgb(227, 227, 227);
    border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #9b9b9b;
    border: solid 3px transparent;
}
.head {
    color: #525f66;
    font-weight: bold;
    margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.newsTitle{
    margin-left: 2px;
    font-weight: bolder;
}

.newsText {
    margin-left: 20px;
    
}

:global(.carousel-control-prev) {
    /* justify-content: start !important; */
    width: 5% !important;
}

:global(.carousel-control-next) {
    /* justify-content: end !important; */
    width: 5% !important;
}

/*::-webkit-scrollbar {
    display: none;
}*/

.tlo{
    height:1100px;
     width:1100px;
    transform: rotate(260deg);
    opacity: 0.2;
    z-index: -1;
    top: 0px;
    right: -15%;
    position: fixed;
}

.linkifyer a{
    color: #99ce6d;
    overflow-wrap: break-word;
}

.carImg1 {
    height:calc(80vw*0.48);
    width: 100%;
}

.carImg2 {
    display: none;
    height:400px;
    width: 100%;
}

.carImg3 {
    display: none;
    height:400px;
    width: 100%;
}

.carImg4 {
    display: none;
    height:400px;
    width: 100%;
}

.carImg5 {
    display: none;
    height:400px;
    width: 100%;
}

.carImg6 {
    display: none;
    height:400px;
    width: 100%;
}

.carImg7 {
    display: none;
    height:400px;
    width: 100%;
}


@media (min-width: 1000px) {
    .carImg1{
        height: 400px;
    }

   
  }

  @media (min-width: calc(1.1*1300px)) {
    .carImg1{
        display: none;
    }
    .carImg2{
        display: block;
    }
  }

  @media (min-width: calc(1.1*1500px)) {
    .carImg2{
        display: none;
    }
    .carImg3{
        display: block;
    }
  }

  @media (min-width: calc(1.2*1800px)) {
    .carImg3{
        display: none;
    }
    .carImg4{
        display: block;
    }
  }

  @media (min-width: calc(1.2*2300px)) {
    .carImg4{
        display: none;
    }
    .carImg5{
        display: block;
    }
  }

  @media (min-width: calc(1.2*2800px)) {
    .carImg5{
        display: none;
    }
    .carImg6{
        display: block;
    }
  }

  @media (min-width: calc(1.2*3600px)) {
    .carImg6{
        display: none;
    }
    .carImg7{
        display: block;
    }
  }




  @media (max-width:1000px){
    .leftLatest{
        font-size: x-large;
    }

    .leftTitle{
        font-size: large;
        font-weight: bold;
    }
    .capt{
        top: 1.25rem;
    }
  }

  @media (max-width:950px){
    .leftLatest{
        font-size: larger;
    }

    .leftTitle{
        font-size: medium;
        font-weight: bold;
    }

    .left{
        font-size: small;
    }
    .capt{
        top: 0.5rem
    }
  }


  @media (max-width:800px){
    .leftLatest{
        font-size: large;
    }

    .leftTitle{
        font-size: smaller;
        font-weight: bold;
    }

    .left{
        font-size: small;
    }

    .capt{
        top: 0rem
    }

    .head{
        font-size: smaller;
    }

    .newsText{
        font-size: small;
    }
  }


  @media (max-width:750px){
    .leftLatest{
        font-size: medium;
    }

    .leftTitle{
        font-size: small;
        font-weight: bold;
    }

    .left{
        font-size: x-small;
    }

    .capt{
        top: 0rem
    }
  }


  @media (max-width:600px){
   .newsTitle{
    font-weight: bold;
   }
   .newsText{
    font-size: 10px;
   }
  }


  @media(max-width:500px){
    .newsTitle{
       
        font-size: 12px;
       }
    .leftTitle{
        font-size: x-small;
       
    
    }
    .left{
        font-size: xx-small;
    }
    .newsText{
        font-size: 9px;
       }

  }

  @media(max-width:450px){
    .leftTitle{
        margin-top: 5px;
       
    
    }
    .hr{
        margin-top: 3px;
    }

    .left{
        margin-top: 5px;
    }

    .newsTitle{
        font-size: 11px;
       }
       .newsText{
        font-size: 8px;
       }

  }

  @media(max-width:400px){

    .leftLatest{
        font-size: small;
    }
    .leftTitle{
        font-size: xx-small;
       
    
    }
    .hr{
        margin-top: 3px;
    }

    .left{
        font-size: 8px;
    }

    .newsTitle{
        font-size: 10px;
       }
       .newsText{
        font-size: 7px;
       }

  }

  @media(max-width:350px){

    .leftLatest{
        font-size: small;
    }
    .leftTitle{
        font-size: 8px;
       
    
    }
    .hr{
        margin-top: 2px;
    }

    .left{
        font-size: 7px;
        margin-top: 3px;
    }
    .newsTitle{
        font-size: 9px;
       }
      
  }