.menu{
   height:100vh;
    width: 95%;
    
   
}

.filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-left: 5%;
 
    
   
    
    
}

.tlo{
   
     width:1300px;
    transform: rotate(230deg);
    opacity: 0.2;
    z-index: -1;
    top: 200px;
    right: -25%;
    position: fixed;
}

@media (max-width:900px){
    .menu{
        width: 100%;
    }
}


@media (max-width:400px){
    .filter{
       margin-left:2%
    }
}