.mainBlock{
    width: 100%;
    display:flex;
    flex-direction: column;
    
    border-radius: 15px;
    margin-top: 20px;
}

.roles{
   margin-top: 40px;
}

.buttonRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
}

.button{
    height: 40px;
    width: 100px;
    margin-left: 25px;
    margin-right: 35px;
    border-radius: 5px;
    border: solid 2px rgb(197, 197, 197) ;
    color: #6d6d6d;
    font-weight: bold;
}

.button:hover{
    cursor:default;
}

.roleList{
  margin-top: 20px;
  font-weight: bold;
  font-size: 17.5px;
}

.iconRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.userType{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
}

.block{
  height: 100px;
  width: 100px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.adminBlock{
  background-color: #2ad0e3;
}

.userBlock{
  background-color: #a4a4a4
}

.moderatorBlock{
  background-color: #87ddbf;
}

.ambasadorBlock{
  background-color: #9a9cd7;
}

.icon{
  font-size: 50px;
  color: white;
}

.activeApply{
    background-color:#99ce6d ;
    color: white;
}

.activeApply:hover{
    cursor:pointer
}



.activeChange{
    background-color:#ffffff ;
}

.activeChange:hover{
cursor:pointer
}

.roleLine{
  margin-top: 5px;
  font-weight: bold;
  width: calc( 260px ); ;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.check{
    background-color: transparent;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    transform: scale(1.4);
    border-radius: 2px;
    
    outline: none;
    cursor: pointer;
    margin-left: auto;
    border: 2px rgb(154, 154, 154) solid;
  }

  .adminCheck{
    background-color: #2ad0e3;
  }

  .ambasadorCheck{
    background-color: #9a9cd7;
  }

  .moderatorCheck{
    background-color: #87ddbf;
  }
  .check::before {
    content: "";
    font-size: 15px;
    color: #fff;
    position: absolute;
    left: 2px;
    top: -2px;  
    margin-left: auto;
    border:   #ffffff;
  }

  .status{
    margin-top: 50px;
    background-color: rgb(246, 246, 246);
    width: inherit;
    border-radius: 20px;
    height: 350px;
  }

  .statusList{
   margin-left: 40px;
   margin-top: 20px;
  }

  .statusLine{
    display: flex;
    flex-direction: row;
    width: calc( 260px );
    margin-top: 10px;
    font-size: 18px;
    align-items: center;
    color: #737373;
  }

  .label{
    font-weight: bold;
    margin-left: 10px;
  }

  .tokens{
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#99ce6d;
    font-weight: bold;
    font-size: 20px;
  }


  .checkMark{
    width: 25px;
    height: 25px;
    background-color: #99ce6d;
    border: 2px rgb(154, 154, 154) solid;
    border-radius: 5px;
  }

  .checkMarkDisabled{
    width: 25px;
    height: 25px;
    background-color: rgb(154, 154, 154);
    border: 2px rgb(154, 154, 154) solid;
    border-radius: 5px;
  }

  .exMark{
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-radius: 5px;
    border: 2px rgb(154, 154, 154) solid;
  }

  .adminLabel{
    color: #2ad0e3;
  }

  .moderatorLabel{
    color: #87ddbf;
  }

  .ambasadorLabel{
    color: #9a9cd7;
  }