.formBlock{
    margin-top: 20px;
    margin-left: 20px;
    
    height:250px
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name{
    margin-left: 10px;
    border: none;
    
}

.label{
    font-size: large;
    color:rgb(27,68,128);
}

#name_in{
    width: 300px;
    height:45px;
    background-color: white;
    padding-left: 10px;
}
