.block{
    margin-top: 10px;
    
    display: flex;
    flex-direction: column;
    width:1050px;
  
  
}

.row{
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 10px;
}



.rowName{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

}

.filterButton{
    border: none;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:gray;
    justify-content: start;
    margin-left: 10px;
}

#nameInput{
    margin-left: 10px;
    width:300px;
    height: 25px;
}


.title{
    font-size: larger;
    font-weight: bold;
    color:rgb(27,68,128);
    margin-left: -5%;
    
}

.subTitle{
    font-size: larger;
    font-weight: bold;
    color:rgb(27,68,128);
    margin-left: -2%;
    margin-top: 10px;
}


