.block{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: inherit;
    background-color: #f4f3f3;
    margin-top: 10px;
    height: 130px;
    border-radius: 10px;
}

.firstRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.sq{
    height:50px;
    width:50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.paid{
    background-color: #94C66B;;
}

.unpaid{
    background-color: #BFBDBD;
}


.date{
    margin-left: 20px;
    font-weight: bold;
    color: rgb(87, 87, 87);
}

.cost{
    margin-left: auto;
    margin-right: 20px;
    font-weight: bold;
    color: rgb(87, 87, 87);
}

.secondRow{
    margin-top: 5px;
    margin-left: 90px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
}

.linkIcon{
    margin-left: 3px;
    font-size: 10px;
    margin-top: 6px;
}

a{
    text-decoration: none;
}

.success{
    
    color:green
}

.fail{
    
}

.linkText{
    margin-left: 35px;
    width: 90px;
    text-align: right;
}

.downIcon{
    margin-left: 25px;
}

.details{
    display: flex;
    flex-direction: row;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
    justify-content: space-between;
}

.descLink{
    display: flex;
    margin-right: 10px;
}

.euro{
    font-size: 30px;
    color: white;
}

@media (max-width:600px){
   .cost{
    display: none;
   }
   .date{
   font-size: 15px;
}
}

@media (max-width:400px){
    .date{
        margin-left: 10px;
    }

    .secondRow{
        margin-left: 80px;
    }
}