.menuBlock{
    width: 90%;

    
}

.title{
    font-size: xx-large;
    color:rgb(27,68,128);
    margin-top: 40px;
    
}

.subtitle{
    margin-top: 50px;
    font-size: x-large;
    color:rgb(27,68,128);
}

.subtitle2{
    margin-top: 220px;
    font-size: x-large;
    color:rgb(27,68,128);
}

.break{
    height: 50px;
}


@media (max-width:1400px){
    .subtitle2{margin-top: 650px;}
}