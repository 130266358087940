.block {
	color: white;
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: row;
	font-size: x-large;
	width: 100%;
}

.text {
	margin-left: 30px;
}

.text:hover {
	font-weight: bold;
	color: white;
}

.text_active {
	margin-left: 30px;
	color: #98cf6d;
	text-decoration-thickness: 1px;
}

.text_active:hover {
	font-weight: bold;
}

.menu_el {
	width: auto;
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: row;
}

.user_menu {
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	font-size: large;

	margin-left: auto;
}

.link {
	color: white;
	text-decoration: none;
}

.link:hover {
	color: inherit;
}

.coinMenu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
	text-decoration: none;
	margin-left: -50px;
}

.coinMenu:hover {
	color: gold;
}

.coins {
	font-size: 40px;
}

.emAndBut {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: auto;
}

.noDisp {
	opacity: 0;
	display: none;
}

.userIcon {
	font-size: 30px;
	margin-top: 3px;
}

.userIconBut {
	background-color: #525f66;
	border: none;
	color: white;
	margin-left: 15px;
}

@media screen and (max-width: 1350px) {
	.userIconBut {
		display: none;
	}
	.email {
		display: none;
	}
}

@media screen and (max-width: 950px) {
	.menu_el {
		font-size: 20px;
	}
}

@media screen and (max-width: 600px) {
	.menu_el {
		font-size: 16px;
		font-weight: bold;
	}
}

@media screen and (max-width:500px){
    .extraLink{
        display: none;
    }

    .menu_el{
        width: 100%;
        justify-content: space-evenly;
        font-size: 20px;
    }
}

