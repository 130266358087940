.block{
  
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    flex-direction: column;
    height: 100vh;
    
}

.tlo{

     width:1100px;
    transform: rotate(260deg);
    opacity: 0.2;
    z-index: -1;
    top: 0px;
    right: -35%;
    position: fixed;
}

.error{
    width: 300px;
   
    display: flex;
    flex-direction: row;
    height: 180px;
    color: #4f4f4f;
    font-weight: bold;
    font-size: 100px;
    justify-content: space-between;
}

.second{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 16px;
}

.link{
    margin-left: 5px;
    
    color:
    #0088bb
}

.link:hover{
    color:#99ce6d
}