.modal {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }

  


.modal_content {
    background-color: #e2e3e5;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 70%;
    min-height:300px;
    max-height: 500px;
  }



.row{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    
}

.title{
    font-weight: bold;
}

.nameText{
    margin-left: 5px;
}

.descText{
    margin-left: 5px;
    width: 80%;
    display: block;
    min-height:100px;
    max-height: 300px;
    overflow:auto;
}

.refText{
    margin-left:5px;
    height: 45px;
    overflow: auto;
}

.button{
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border: none;
    border-radius: 5px;
    color: white;
    height: 40px;
    width: 90px;
}

.buttonRow{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}


.modalBlure {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
  }
  
  
  
  
  .subModalBox {
    background-color: transparent;
    margin: 120px auto; 
    padding: 20px;
    border: 2px solid rgba(209, 209, 209, 0.5);
    border-radius: 10px;
    width: 600px;
    min-height:200px;
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(40px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }

  .subModalElements{
    display: flex;
    flex-direction: column;
    
    width: inherit;
    height: inherit;
    
    align-items: center;
    
  }