.block{
    height: 100px;
    margin-bottom: 5px;
    background-color: green;

}

.blockWhole{
    height: 310px;
    margin-bottom: 5px;
    background-color: green;
    animation: open 0.5s;
}

.closed{
    height: 100px;
    margin-bottom: 5px;
    background-color: green;
    animation: close 0.5s;
}

@keyframes open {
    0%{
        height: 100px;
    }
    100%{
        height: 310px;
    }
    
}

@keyframes close {
    0%{
        height: 310px;
    }
    100%{
        height: 100px;
    }
}
