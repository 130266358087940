.block{
    height:75vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   
    width: 800px;
    
 
}


.square{
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    background: linear-gradient(150deg, rgba(81,95,102,0.3248074229691877) 0%, rgba(81,95,102,0.9248074229691877) 100%);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
}

.link{
    color:black;
    text-decoration: none;
}


.firstBlock{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

}


@media (max-width:850px){
    .square{
        height: 200px;
        width: 200px;
    }

    .block{
        width: 600px;
    }
}


@media (max-width:650px){
    .square{
        height: 170px;
        width: 170px;
        font-size: smaller;
    }

    .block{
        width: 500px;
    }
}

.userInfo{

    text-align: center;
}
@media (max-width:400px){
    .square{
        height: 120px;
        width: 120px;
        font-size: smaller;
    }

    .block{
        width: 300px;
    }
}