.rowInRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    color:rgb(27,68,128);
   
    width: 550px;
}

.rowInRow2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    color:rgb(27,68,128);
   
    width: 450px;
}

.row{
    display: flex;
    flex-direction: row;
    width: 1100px;
    justify-content: space-between;
    margin-top: 40px;
   
}

.column{
    display: flex;
    flex-direction: column;
    height: 180px;
    
    justify-content: space-between;
}

.regulatory{
    color:rgb(27,68,128);
    font-size: x-large;
}

.slashed{
    margin-left: 50px;
}



.guidance{
    
    inline-size: 90px; 
    overflow-wrap: break-word;
    
}


@media(max-width: 1400px){
    .row{
        flex-direction: column;
        height: 800px;
       
        align-items: start;
        justify-content: start;
       
    }
    .column{
        height: 500px;
        
    }

    .rowInRow{
        width: 450px;
    }
}