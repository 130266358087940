.block{
    color: rgb(255, 255, 255);
    font-size: x-small;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin-left: 15px;
}

.sq{
   
    background-color: rgba(255, 255, 255, 0.551);
   font-weight: bold;
   font-size: larger;
    margin-left: 3px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 30px;
}

.number{
    display: flex;
    flex-direction: row;
    
}

.comma{
    font-weight: bold;
    font-size: x-large;
     margin-left: 3px;
     width: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 5px;
     height: 35px;
}


@media  (max-width: 1020px) {
    
    .block{
        height:70px;
       
    }

    .sq{
        width: 22;
    }
}

@media  (max-width: 950px) {
    
    .block{
        height:65px;
       
    }

    .sq{
        width: 15;
        height:30px
    }
}

@media  (max-width: 850px) {
    
    .block{
        display: none;
       
    }

    
}