.editBox{
    display: flex;
    justify-content: start;
    align-items: start;
    width: 95%;
    flex-direction: column;
}

.title{
    color:rgb(27,68,128);
    font-size: xx-large;
    margin-top: 40px;
}