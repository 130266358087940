.modal {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }


.modal_content {
    background-color: #e2e3e5;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 500px;
    height:150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40%;
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Ok{
    margin-top: 15px;
    width: 70px;
    background-color: #e82424;
    color: white;
    border-radius: 10px;
    border: solid;
    border-color:#e82424 ;
  }

  .OkGood{
    margin-top: 15px;
    width: 70px;
    background-color: rgb(27,68,128);
    color: white;
    border-radius: 10px;
    border: solid;
    border-color:rgb(27,68,128) ;
  }

  .label{
    margin-top: 10px;
  }

  .buttonRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:50%;
    
  }