.mypanel > [class~="p-dropdown"] {
   
}

.mypanel > [class~="p-dropdown-label"] {
 
  width: 250px;
  
}


.mypanel2 > [class~="p-multiselect"] {
  background-color: #07c4e8;
  color: #ffffff;
  width: 250px;
  
}


.mypanel2 > [class~="p-multiselect-label-container"] {
  
  width: 250px;
  
}

.mypanel2 > [class~="p-chips-token-icon"] {
  background-color: #e85907;
  color: #ffffff;
}