.green{
 background-color: rgb(212, 255, 212);
 box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
 margin-top: 10px;
 height: 50px;
 width:100%;
 font-size: small;
 color:green;
 display: flex;
 flex-direction: row;
 align-items: center;
 border-radius: 5px;
}

.red{
 background-color: #f443367d;
 box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
 margin-top: 10px;
 height: 50px;
 width: 100%;
 font-size: small;
 display: flex;
 flex-direction: row;
 align-items: center;
 border-radius:5px

}


.blue{
    background-color: #e7e7e7 ;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    margin-top: 10px;
    height: 50px;
    width: 100%;
    font-size: small;
    color:rgb(80, 80, 80);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
   }

.refs{
    background-color: #8bc34aab;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    margin-top: 10px;
    width: 100%;
    font-size: small;
    max-height:220px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: start;
}

.status{
    font-size: x-large;
    font-weight: bold;
    
}

.link{
   margin-left: 5px;
    width:280px;
    overflow-wrap: break-word;

    
}

.refTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    margin-top: 20px;
}

.refTitleText{
    margin-left: 5px;
}

.refsList{
    margin-top: 10px;
    margin-left: 7%;
    display: block;
    overflow: auto;
    width: 80%;
    min-height: 50px;
    max-height:120px;
    margin-bottom: 10px;
}

.refRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.GRText{
    margin-left: 5px;
   margin-top:1px
   
}

@media(max-width: 1215px){
   .blue{
    width: 100%;
   }

   .refs{
    width: 100%;
   }

   .red{
    width: 100%;
   }
   
}


@media(max-width: 1215px){
    .blue{
     width: 100%;
    }
 
    .refs{
     width: 100%;
    }
 
    .red{
     width: 100%;
    }
    
 }