.menu{
    height:100vh;
     width: 95%;
    
 }
 
 .filter{
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
 }
 
 .tlo{
     height:1100px;
      width:1100px;
     transform: rotate(260deg);
     opacity: 0.2;
     z-index: -1;
     top: 0px;
     right: -15%;
     position: fixed;
 }

 .blockLabel{
    margin-top: 50px;
    margin-left: 3%;
    font-size: 30px;
    color:rgb(79,79,79);
 }

 .line{
    background-color: rgb(79,79,79);
    width: 40%;
    height: 2px;
    margin-left: 3%;
 }