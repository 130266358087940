.block{
    display: flex;
    flex-direction: column;
    height: calc( 100vh - 110px);
    position: fixed;
    width: 100%;
    overflow: hidden;
   
}
.rest{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%! important; 
    overflow-y: auto;
}

.bars{
    margin-left: calc( 50% - 400px );
    margin-top: 280px;
    position: fixed;
    left: 0px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activeBar{
    height: 40px;
    width: 10px;
    background: #0088BC;
	background: linear-gradient(
		180deg,
		#0088BC 0%,
		#99CE6E 100%
	);
    border-radius: 10px;
    margin-top: 10px;
}

.inactiveBar{
    height: 40px;
    width: 10px;
    background-color: #D9D9D9;
    margin-top: 10px;
    border-radius: 10px;
}

.mainPart{
    overflow-y: hidden;
    height: 100%;
    position: relative;
}


.section{
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 100%;
    position: relative;
    overflow-y: auto;
    justify-content: center;
}

.sectionBody{
   
    display: flex;
	flex-direction: row;
	text-align: center;
 
}

.sectionBodyMain{
    display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
    overflow-y: hidden;
   
}

.logoDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo{
    width: 350px;
}
.colorLine {
	margin-top: 0px;
	width: 150px;
	height: 6px;
	border-radius: 30px;
	background: #0088BC;
	background: linear-gradient(
		260deg,
		#0088BC 0%,
		#99CE6E 100%
	);
}


.text{
    margin-top: 20px;
    text-align: justify;
    width: 80%;
    font-size: 24px;
    font-weight: bold;
}
.short{
    width: 70px;
    margin-top: 20px;
}

.greenTxt{
    color: #99CE6E;
}

.downBut{
    margin-bottom: 0px;
    margin-top: 0px;
}
.slideBut{

    height: 60px;
    width: 60px;
    border-radius: 50%;
    
  
    border:none;
    z-index: 10;
    background-color: inherit
    
    }
.noDisplay{
  opacity: 0.01;
}

.butArr{
    color: #515f65;
    font-size: 20px;
    box-shadow: 0px 5px 5px rgb(195, 195, 195);
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #d1d1d1;
    
   
}

.blob1{
    position: absolute;
    width: 1500px;
    top: 0%;
    left: -700px;
    transform: rotate(140deg);
    opacity: 0.5;
}

.blob2{
    position: absolute;
    width: 1500px;
    top: 50%;
    right: -500px;

    transform: rotate(140deg);
    opacity: 0.5;
}

/* .first{
    background-color: red;
}

.second{
    background-color: green;

}
*/
.third{
    margin-top: -210px !important;
    background-color: yellow ;
} 

@media screen and  (max-height:1000px) {
    .logo{
        width: 280px;
    }

    .rest{
        height: calc( 99% - 160px );
    }

    .bars{
        margin-top: 240px;
    }
}

@media screen and  (max-height:850px) {
    .text{
        font-size: 20px;
    }

    .bars{
        margin-top: 190px;
    }
}

@media screen and  (max-height:750px) {
    .text{
        font-size: 18px;
    }
    .bars{
        margin-top: 170px;
    }
}

@media screen and  (max-width:1700px) {
    .blob1{
       left: -800px;
    }

    .blob2{
        right: -600px;
     }
}

@media screen and  (max-width:1500px) {
    .blob1{
       left: -900px;
    }

    .blob2{
        right: -700px;
     }
}

@media screen and  (max-width:1300px) {
    .blob1{
       left: -1000px;
    }

    .blob2{
        right: -800px;
     }
}

@media  (max-width:800px){
    .text{
        margin-top: 20px;
        font-size: 15px;
        width: 70%;
    }

    .section{
        width: 600px;
    }
    .bars{
        margin-left: calc( 50% - 280px );
   
    }

}

@media  (max-width:600px){
    .text{
        margin-top: 20px;
        font-size: 12px;
        width: 70%;
    }

    .section{
        width: 400px;
    }
    .bars{
        margin-left: calc( 50% - 200px );
        margin-top: 130px;
    }
    .logo{
        width: 200px;
    }
    .colorLine {
      
        width: 100px;
       
    }
    .short{
        width: 50px;
    }

}

@media (max-width:450px){
    .block{
        height: calc( 100vh - 90px);
    }
}

@media (max-width:400px){
    .section{
        width: 300px;
    }
    .bars{
        margin-left: calc( 50% - 170px );
        margin-top: 150px;
    }
}