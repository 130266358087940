
button:hover{
    transform: scale(1)
}

.explore_block{
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 100px;
    
   
}

.exp_menu{
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width:85%;
    height: 750px;
    

    
}

.exp_menuN{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:85%;
    height: 820px;
}

.exp_menuF{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:85%;
    height: 1000px;  
}

.exp_menuFN{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:85%;
    height: 1070px;  
}

.details_block{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    flex-wrap: wrap;
    
}

body, html {
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    /* font-family: 'Raleway', sans-serif; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    font-family: 'DM Sans', sans-serif;
  }

.explore_whole{
    position: relative;
  min-height: 100vh;
  
}

.details_menu{
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    width:85%;
    height: fit-content;
  
    

}

.manageAddBlock{
    display:flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    padding-bottom: 100px;
    
    
   
}


.manageAdd{
   
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100vh
    
   
}


.homeAddBlock{
    display:flex;
    flex-direction: row;
    
    flex-wrap: nowrap;
    padding-bottom: 100px;

}

.noPadBot{
    padding-bottom: 0px;
}


.homeAdd{
   
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100%
    
   
}


@media (max-width:1450px){
    .exp_menuFN{
        height: 1320px;
    }
}


@media (max-width:1215px){
    .details_menu{
        height: 1500px;
    }
}

@media (max-width:400px){
    .exp_menuN{
      
        width:95%;
     
    }
    
    .exp_menuF{
       
        width:95%;
    
    }
    
    .exp_menuFN{
      
        width:92%;
     
    }
}