.modal {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }


.modal_content {
    background-color: #f0eeee;
    position: absolute;
    top:30%;
    left:43%;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 500px;
    height:350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
  }

  .modal_content2 {
    background-color: #f0eeee;
    position: absolute;
    top:30%;
    left:43%;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 500px;
    height:250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
  }

  .modal_content1 {
    background-color: #f0eeee;
    position: absolute;
    top:30%;
    left:43%;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 500px;
    height:200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
  }

  .addForm{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    
    
  }
.realAddForm{
  width: 100%;
  display: flex;
  

  position: relative;
  flex-direction: column;
  height:100%
}

.formEle{
 
  height:80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
  .title{
    color:rgb(27,68,128);
    font-size:large ;
    font-weight:bold ;
  }
  .formRow{
    display: flex;
    flex-direction: row;
    width:80%;
    height:35px;
    margin-top: 10px;
    margin-left: 10%;
    
    align-items: center;
    justify-content: space-between;
   
  }

  .formRowArea{
    display: flex;
    flex-direction: row;
    width:80%;
    height:55px;
    margin-top: 10px;
    margin-left: 10%;
    align-items: center;
    justify-content: space-between;
   
  }

.formLabel{
  color:rgb(27,68,128);
  font-size: large;
  
}

.buttonRow{
  
position: absolute;
top:80%;
left: 35%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}

.submitButton{
  height: 40px;
  width: 70px;
background-color:#99ce6d;
box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
border: none;
border-radius: 10px;
color: white;
font-size: 16px;


}

.closeButton{
  height: 40px;
  width: 70px;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  background-color: rgb(120, 120, 120);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
  color: white;
  font-size: 16px;
}

.error{
  margin-top: 20px;
  text-align: center;
  font-size: small;
  color: rgb(253, 112, 112);
}

#inputer{
  width: 200px;
  height:30px;
  background-color: white;
  margin-left: auto;
}

#editInputer{
  width: 200px;
  height:30px;
}

#inputerArea{
  width: 200px;
  height:50px;
  margin-left: auto;
}


.OkGood{
  margin-top: 15px;
  width: 70px;
  background-color: #525f66;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
  color: white;
  border-radius: 10px;
  border: solid;
  border-color:rgb(27,68,128) ;
}



.modal_content_info {
  background-color: #e2e3e5;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 700px;
  height:350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30%;
}