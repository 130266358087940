.block{
   
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
  
   margin: auto;
   height: 700px;
    width: 100%;
    
   
}

.userInfo{
    
    width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;
    
}

.userRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputDiv{
    margin-left:auto;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.companyInfo{
  
    height: 500px;
    width: 400px;
    
}

.companyWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.penBut{
    background-color: #eae9e9;
    border-radius: 10px;
    margin-left: 5px;
    border-width: 1px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    height: 30px;
    color:#525f66;
    border-color: #525f66;
}

.entry{
    max-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
   
    
}

.label{
    width: 300px;
    margin-top: 15px;
}

.postalCodeLabel{
    width: 200px;
    margin-top: 15px;
}



.userInfo{
    background-color: #525f6682;
    color:white;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
  
}

.userRow{
    margin-left: 15px;
}

.entryVal{
    max-width: 190px;
   
  
    overflow-wrap: break-word;
}

.inputDiv2{
    margin-left: 10px;
}




.panel{
    width: 33%;
    height: 600px;
    
}
.line{
    width: 1px;
    min-height: 700px;
    height: calc(100vh - 140px);
    background-color: rgb(197, 197, 197);
}

.firstPanel{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.userIcon{
    color: #525f66;
    font-size: 90px;
    margin-top: 100px;

}

.firstName{
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
}

.email{
    margin-top: 10px;
}

.marginer{
    margin-left: 20px;
    margin-right: 20px;
   
}

.secondLabel{
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
}



#input{
    height: 45px;
    background-color: white;
    width: 100%;
    padding-left: 10px;
}

.secondCompany{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.secondCompanyItem{
    width: calc(50% - 10px);
}

#smallInput{
    height: 45px;
    background-color: white;
    width: 100%;
    padding-left: 10px;
}

.saveButton{
    margin-top: 20px;
    width: 400px;
    margin-left: calc((100% - 400px) / 2);
    height: 50px;
    color: white;
    background-color: #8bc34aab;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0.25rem;
    border-style: none;
}

.saveButton:hover{
    border-style: solid;
    border-color: #98ba9a;
}

.tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   bottom: -20%;
   left: -25%;
   position: fixed;
  }

  .userFiller{
    display: none;
  }

  .modalBlure {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
  }
  
  
  .modalBox {
    background-color: #e2e3e5;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 50%;
    height:200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .subModalBox {
    /* background-color: transparent; */
    background-color: white;
    margin: 120px auto; 
    padding: 20px;
    border: 2px solid rgba(209, 209, 209, 0.5);
    border-radius: 10px;
    width: 400px;
    height:180px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(40px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }
  .blure{
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
  .loginBar{
    background-color: #98cf6d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0 ;
    height: 70px;
  }
  .subModalElements{
    display: flex;
    flex-direction: column;
    
    width: inherit;
    height: inherit;
    
  }

  .subModalContent{
    width: inherit;
  }
  .checkMark{
    color: white;
    font-size: 40px;
  }
  .authText{
    font-weight: bold;
    color:#3b4045 ;
    margin-top: 10px;
    text-align: center;
  }

  .buttonRow{
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ok{
    width:120px;
    height:35px;
    background-color: #9d9d9dab;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;

  }

  .noDisp{
    display: none;
  }
  @media (max-width:1300px){
    .saveButton{
        width:280px;
        margin-left: calc((100% - 280px) / 2);
        font-size: 17px;
    }
    .secondCompany{
        flex-direction: column;
    }

    .userFiller{
        display: block;
        height: 84.2px;
    }
    .secondCompanyItem{
        width: 100%;
    }
  }

  @media (max-width:900px){
   
    .firstPanel{
        display: none;
    }
    .userInfo{
        margin-top: 20px;
    }
    .panel{
        width: 50%;
    }
}

@media (max-width:650px){
.block{
    flex-direction: column;
align-items: center;
justify-content: start;
height: calc( 100vh - 230px );
overflow-y: auto;}
.line{
    display: none;
}
.userFiller{
    display: none;
}
.panel{
    width: 70%;
}

.saveButton{
    width: 100%;
    margin-left: 0px;
}
}


@media (max-width:500px){
   
}

@media (max-width:450px){
    .block{
        height: calc( 100vh - 210px );
    }
}
@media (max-width:400px){
  .block{
    overflow-x: hidden;
  }
  .panel{
  }


}