.h2 {
    color: #525f66;
}

.inline {
    display: inline;
}

.button {
    background-color:#525f66 !important;
    border: none !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-weight: bold !important;
   
}

.buttonClear{
    width: 50%!important;
    margin-left: 25%!important;
    margin-top: 10px;
}

.rowMargin {
    margin: 25px !important;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    
    
}

.btnAdd {
    margin-left: 15px;
    
}

.wrapDiv {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    
}

.center {
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
 
}


.menu{
   height:100vh;
    width: 95%;
   
}

.filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   top: 200px;
   right: -25%;
   position: fixed;
}

@media (max-width:1000px){
    .rowMargin{
        flex-direction: column-reverse;
        align-items: center;
    }

    .wrapDiv{
        margin-top: 100px;
        
    }
    .center{
        width: 350px;
    }
}



@media (max-width:450px){
    .rowMargin{
       margin: 0px!important;
       margin-top: 20px!important;
    }

    .center{
        width: 320px;
        overflow-x: hidden;
       
    }

}