.block{
    margin-top: 10px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 10px;
}

.rowName{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.filterButton{
    border: none;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    color:gray;
    justify-content: start;
    margin-left: 10px;
}

#nameInput{
    margin-left: 10px;
    width:300px;
    height: 25px;
}


.title{
    font-size: large;
    color:rgb(27,68,128)
}

