.block{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position:relative;
width: 100%;
height: calc( 100vh - 110px);
overflow: hidden;
}

.title{
    font-family: "League Spartan", sans-serif;
    color: #515F65;
    font-weight: bold;
    font-size: 66px;
    text-align: center;
    margin-bottom: -10px;
    
}



.colorLine {
	width: 140px;
	height: 7px;
	border-radius: 30px;
	background: #0088BC;
	background: linear-gradient(
		260deg,
		#0088BC 0%,
		#99CE6E 100%
	);
    margin-top: 10px;
}

.main{
    width: 100%;

    margin-top: 50px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section{
    height: 420px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    border-radius: 30px;
    box-shadow: 20px 20px 20px rgb(109, 109, 109);
}

.subtitle{
    font-family: "League Spartan", sans-serif;
    font-size: 40px;
    font-weight: bold;
    background: rgb(0,136,188);
    background: linear-gradient(90deg, rgba(0,136,188,1) 0%, rgba(153,206,110,1) 100%);
    width: 100%;
    height: 80px;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.subSubtitle{
    margin-top: 30px;
    font-size: 30px;
    font-weight: bold;
    color:#515F65;
    
}



.txtList{
   
    
    color:#515F65;
    margin-top: 25px;
}


.txtListEl{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.text{
    font-size: 20px;
    font-weight: bold;
    width: 330px;
    text-align: start;

   
}

.textLine{
    margin-top: -8px;
}
.checker{
    margin-right: 20px;
    color:#99CE6E;
    font-size: 24px;
    
}

.txtSubList{
    margin-top: 10px;
}

.lastTxt{
    margin-top: 10px;
}

.breakLine{
    background-color: transparent;
    height: 100%;
    width: 150px;
}

.green{
    color:#99CE6E
}



.blob1{
    position: absolute;
top:-400px;
transform: rotate(200deg);
left: -400px;
width:1200px;
z-index: -100;
opacity: 0.6;
}

.blob2{
    position: absolute;
bottom:-450px;
right: -600px;
width:1200px;
z-index: -100;
opacity: 0.6;
transform: rotate(50deg);
}

@media screen and  (max-width:1000px){
    .main{
        width: 90%;
    
      
    }
}

@media screen and  (max-width:900px){
    .section{
        height: 320px;
        width: 320px;
    }
    .subtitle{
   
        font-size: 30px;
       
       
    }
    
    .subSubtitle{
    
        font-size: 25px;
    }
    .txtList{
   
        margin-top:10px;
    }
    
    
    .txtListEl{
       
        margin-top:10px;
    }
    
    .text{
        font-size: 17px;
        width: 280px;
        margin-left: 5px;
    
       
    }
    
   
    .checker{
        margin-right: 0px;
      
        font-size: 20px;
        
    }

    .title{
       
        font-size: 56px;
        margin-bottom: -16px;
        
    }
    
    
}

@media screen and  (max-width:700px){
    .section{
        height: 220px;
        width: 220px;
        border-radius: 20px;
    }
    .subtitle{
       height: 50px;
        font-size: 20px;
        border-radius: 20px 20px 0px 0px;
    }
    
    .subSubtitle{
        margin-top: 20px;
        font-size: 18px;
    }

    .txtList{
        margin-top: 20px;
    }
   
    
    .text{
        font-size: 12px;
        width: 200px;
        margin-left: 5px;
    
       
    }
    
   
    .checker{
        margin-right: 0px;
      
        font-size:18px;
        
    }

    .title{
       
        font-size: 50px;
        margin-bottom: -16px;
        
    }
    
    
}

@media screen and  (max-width:500px){

    .main{
       flex-direction: column;
       align-items: center;
    }
    .section{
        margin-top:-20px;
    }
    .breakLine{
        background-color: transparent;
        height: 50px;
        width: 150px;
    }
}