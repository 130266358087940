.block{
    height: 500px;
    width: 600px;
}

@media (max-width:1800px){
    .block{
        height: 400px;
        width: 500px;
    }
}
@media (max-width:1000px){
    .block{
        height: 500px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
}