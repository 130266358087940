.list{
    background-color: red;
    height: 315px;
    overflow-y: hidden;
}
.block{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.table_elements{
    display: flex;
    width: 100%;
    justify-content:space-between;
    margin-top: 30px;
    margin-bottom: 30px;
}



.next_but{
    margin-left:10px;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}

.next_but_dis{
    margin-left:10px;
    background-color:rgb(228, 228, 228) ;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}


.pager{
    display: flex;
    flex-direction: row;
    color:rgb(27,68,128);
    
    
   

}

.page_of_pages{
 color:rgb(27,68,128)
}

.page_buttons{
    border:none;
    color:rgb(27,68,128);
    font-size: large;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.page_buttons_selected{
    border:none;
    color:white;
    font-size: large;
    margin-left: 10px;
    background-color: rgb(27,68,128);
    border-radius: 5px;
}

.listWBut{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tlo{
   
    width:1300px;
   transform: rotate(140deg);
   opacity: 0.37;
   z-index: -1;
   top: 200px;
   right: -30%;
   position: fixed;
  }