.block{
    
    margin-top: 50px;
    width:80%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    color:#515F65
}

.logButton{
  background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border:none;
  height: 40px;
  width: 150px;
  color: #fff;
  border-radius: 10px;
  margin-top: 30px;
}

.name{
    font-size: xx-large;
    color:rgb(79,79,79);
}

.line{
    height: 2px;
    background-color: rgb(79,79,79);
    width: 80%;
    margin-top:10px;
}

.rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.namStatus{
    width: 50%;
    min-width: 470px;

}

.general{
    width: 50%;
  
    margin-left: 10px;
    
}

.desc{
  margin-top: 50px;
  font-size: x-large;
  font-weight: bold;
}

.descText{
  margin-top: 20px;
  max-height: 200px;
  
  overflow-y: auto;

}

.row{
  display: flex;
  flex-direction: row;
  margin-top:10px;
  align-items: center;

 
}

.linkRow{
  display: flex;
  flex-direction: column;
  margin-top:10px;
  align-items: start;

 
}

.backLink{
  width: 100%;
  
  display: flex;
}


.reference{
  margin-left: 5px;
  margin-top: 10px;
  overflow-wrap: break-word;
  width: calc(100% - 45px);
 
}

.linker{

  width:100%;
 height: 70px;

  overflow-x: auto;
  display: flex;
  flex-direction: column;
  
}

.subTitle{
  width: 65px;
  margin-top: 10px;
}


.backButton{
  margin-top:20px;
  
  background-color: #525f66;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
  border:none;
  height: 45px;
  width: 100px;
  border-radius: 10px;
  color:white;
  text-align: center;
  margin-left: auto;
}



@keyframes loading{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 1;
    }

    100%{
        opacity: 0%;
    }
}





  .ring
{
  
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:rgb(27,68,128);
  letter-spacing:4px;
  text-transform:uppercase;
  margin-top: 250px;
  margin-left: 50px;
  
 
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid rgb(27,68,128);
  border-right:3px solid rgb(27,68,128);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}


@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }

  
}

.loading_block{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 100px;
   
}

.tlo{
   
  width:1300px;
 transform: rotate(230deg);
 opacity: 0.1;
 z-index: -1;
 top: 200px;
 right: -35%;
 position: fixed;
}

.modalBlure {
  display: block; 
  position: fixed; 
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
}


.modalBox {
  background-color: #e2e3e5;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  height:200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subModalBox {
  /* background-color: transparent; */
  background-color: white;
  margin: 120px auto; 
  padding: 20px;
  border: 2px solid rgba(209, 209, 209, 0.5);
  border-radius: 10px;
  width: 400px;
  height:400px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(40px);
  box-shadow: 0 0 30px rgba(0,0,0,.5);
}

.logInBox{
  height:300px;
  width: 600px;
}

.blure{
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.modalElements{
  display: flex;
  flex-direction: column;
  
  width: 70%;
  height: 100%;
  
}

.subModalElements{
  display: flex;
  flex-direction: column;
  
  width: inherit;
  height: inherit;
  
}

.logInSubModal{
  display: flex;
  flex-direction: row;
  
  width: inherit;
  height: inherit;
}

.logInSubBoxOne{
 
  width: 300px;
  align-items: start;
  margin-left: 10px;
}
.logInSubBoxTwo{
  align-items: start;
  position: relative;;
}
.helloThere{
  position: absolute;
  width: 200px;
  top:350px;
  left: calc( 50% - 100px);
}






.firstLine{
 

}

.firstLineText{
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  text-align: center;
}


.secondLine{
  margin-top: 20px;
  width: 200px;
  font-weight: bold;
  margin-left: calc( 330px - 240px );
  font-size: 20px;
}
.bolder{
  font-weight: bold;
}
.subscribeBut{
  width: 360px;
  margin-left: 20px;
  height: 80px;
  margin-top:20px;
  border: none;
  border-radius: 5px;
  background-color: #98cf6d;;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}


.subscribeBut:hover{
  cursor:pointer;
  border: solid;
  border-color: #98ba9a;
  border-width: 3px;
}

.takeBack{
  background-color: #9d9d9dab;
  height: 40px;
  width: 150px;
  border:none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 20px;
}

.takeBack:hover{
  border:solid;
  border-width: 1px;;
  border-color: #525f66;
  
}

.logInRegBut{
 
  height: 50px;
  width: 155px;
  border:none;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 18px;
}

.loginLink{
  text-decoration: none;
}


.modalText{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 95%;
  margin-top:0px;
  margin-left: 2.5%;
  font-weight: bold;
}
.subscribeModalTitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 95%;
  margin-top:20px;
  margin-left: 2.5%;
  font-weight: bold;
}

.stars{
  height: 30px;
  width: 30px;
  color: #525f66;
  margin-left: 20px;
}

.subModalTitle{
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
}

.subModalContent{
  width: inherit;
}

.subCost{
  color: #525f66;
  margin-top: 15px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 15px;
}

.subList{
  margin-left: 20px;
  margin-top: 20px;
}
.listElement{
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 10px;
}

.listElementText{
  margin-left: 10px;
}

.subInput{display: none;}



.modalText{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.descBack{
  background-color: #F7F7F7;
}

.addNam{
  margin-left: 20px;
  height: 40px;
  width: 150px;
  border:none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
  background-color: #98cf6d;
}

.addNam:hover{
  border:solid;
  border-width: 1px;;
  border-color: #327031;
}

.buttonRow{
  width: calc( 100% - 20px );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.buttonRowLogReg{
  width: calc( 100% - 20px );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 0px;
}

.shortModal{
  height: 120px;
}

.authText{
  
  font-size: 20px;
  width: calc( 100% - 30px);
  margin-left: 5px;
  text-align: center;
}

.authRow{
  display: flex;
  align-items: start;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 10px;
  
}

.dot{
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: grey;
  margin-top: 6.5px;
}

.middleModal{
  height:300px;
  width: 600px;
}


.dearUser{
  font-weight: bold;
  color: #525f66;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  margin-top: 10px;
  font-size: 20px;
}

.space{
  height: 50px;
}

.tokenModel{
  height: fit-content
}

.space{
  height: 10px;
  width: 100%;
}

.divideLine{
  height: inherit;
width:1px;
background-color: rgb(200, 200, 200);
margin-left: 10px;
}

.logInRegAccesSub{
  margin-top: 20px;
}

.logInRegAccesSecondSub{
  margin-top: 10px;
}

.resend{
  color:rgb(38, 107, 255)
}
.resend:hover{
  cursor: pointer;
}

.loginBar{
  background-color: #98cf6d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0 ;
  height: 70px;
}

.hand{
  width: 50px;
}

.checkMark{
  color: white;
  font-size: 40px;
}

@media (max-width:1100px){
  .name{
    font-size: x-large;
  }
}


@media (max-width:1215px){
  .rows{
   flex-direction: column;
  }

  .general{
    margin-top: 20px;
  }

  .namStatus{
    width: 100%;
  }

  .general{
    width: 100%;
    margin-left: 0px;
  }

  .descText{
    margin-top: 20px;
   
    max-height: 150px;
  
  }
}

@media screen and (max-width:750px){
  .logInBox{
    width: 500px;
    height: 270px;
  
  }
  .modalBlure {
    position: absolute;
 
  }

  .logInSubModal{
    flex-direction: column;
    
  }

  .logInSubBoxTwo{
    width: 500px;
  }
 
.firstLineText{
  font-size: 16px;
  margin-left: 0px;
  margin-right: 0px;
}

  .secondLine{
    width: 100% ;
    margin-left: 0px ;
    text-align: center;
    
  }

  .buttonRowLogReg{
    margin-left: 0px ;
    justify-content: center;
    width: 100% ;
  }

  .divideLine{
    height: 3px ;
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .logInSubBoxOne{
    width: 100%;
    margin-left: 10%;
    height: auto;
    margin-bottom: 20px;
  }

  .firstLineText{
    margin-right: 5px;
  }
}


@media (max-width:650px){
  .block{
}

.linker{
  height: 80px;
  font-size: small;
}
}


@media (max-width:550px){
  .namStatus{
  
    min-width: 270px;
    
  }
  .linker{
    height: 50px;
    font-size: x-small;
  }

  .descText{
    font-size: x-small;
  }

  .descText{
    max-height: 100px;
  }

  .logInBox{
    width: 300px;
    height: 300px;
  }
  .logInSubBoxTwo{
    width: 300px;
  }

  .firstLineText{
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
  }

  .secondLine{
    width: 100%;
    font-size: 18px;
    
  }
  

  .buttonRowLogReg{
    width: 100%;
    margin-left: 0;
    justify-content: space-evenly;
  }
  .logInRegBut{
    font-size: 15px;
    margin-left: 0px;
    width: 120px;
  }

  .divideLine{
   
  }

  .logInSubBoxOne{
    font-size: 12px;
  }
}

@media screen and (max-width:450px){
  .middleModal{
    width: 300px !important;
    height: 320px;
  }

  .buttonRow{
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .takeBack{
    margin-left: 5px;
    margin-right: 5px;
  }

  .addNam{
    margin-left: 5px;
    margin-right: 5px;
    width: 260px;
  }

  .subModalBox{
    width: 300px;
  }

  .subModalTitle{
    font-size: 15px;
  }
  .subscribeBut{
    width:260px
  }

  .listElement{
   
    width: 260px;
    font-size: 14px;
  }
  .buttonRowLogReg{
    flex-direction: column;
  }

 
  .logInRegAccesSub{
    margin-top: 0px;
  }
  .listElementText{
    margin-left: 5px;
    font-size: 10px;
  }
  .divideLine{
    margin-top: 0px;
    display: none;
  }

  .logInBox{
    width: 300px;
    height: 370px;
  }
  .takeBack{
    width: 260px;
  }
}