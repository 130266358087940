.footer {
    position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;  
  background-color: rgb(82, 95, 102);
  display: flex;
  justify-content: center;
  
  }

  .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    width:97.5%;
    justify-content: space-between;
    
    
    
  }

  .icons{
    display: flex;
    flex-direction: row;
    align-items: center;
 
    
  }

  .icons a{
    color: rgb(177, 177, 177);
    margin-left: 5px;
  }

  .logo{
    width: 150px;
   
  }

  .contact{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height:100%;
    width: 250px;
   
  }

  .contacters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgb(177, 177, 177);
    align-items: center;
    
  }

  .a{
    color:inherit
    
  }

  .iconStyles{color:rgb(177, 177, 177);
     font-size:40px}

  .smallIcons{color:rgb(177, 177, 177);
     font-size:25px}

     .content2{
      display: none;
    }

  @media (max-width:1000px){
    .contacters{
      display: none;
    }
    .phone{
      display: none;
    }
    .a{
      display: none;
    }
   .contacters{
    justify-content: end;
    width: fit-content;
  
   }
   .smallIcons{
    margin-right: 10px;
   }
   .linked{
    display: none;
   }
   .contact{
    width: fit-content;
   }
   .logo{
    width: 120px;
 
   }
   .icons{
    margin-right: 50px;
   }
  }

  @media  (max-width: 850px) {

    

    .icons a{
      font-size: small;
    }

    .a{
      font-size: small;
    }

    .phone{
      font-size: small;
    }

 
  }


  @media  (max-width: 850px) {

    .linked{
      width: 300px;
      overflow-wrap: break-word;
  
    }
  }


  @media  (max-width: 650px) {

  
    .logo{
      width: 100px;
   
     }
  }


    @media  (max-width: 600px) {


    .a{
      font-size: x-small;
    }

    .phone{
      font-size: x-small;
    }

   
  }

  @media  (max-width: 450px) {

    .linked{
      width: 120px;
      overflow-wrap: break-word;
      font-size: xx-small;
    }

    

 

  .a{
    font-size: xx-small;
  }

  .phone{
    font-size: xx-small;
  }



  .smallIcons{
    font-size: 20px;
  }
  .iconStyles{
    font-size: 30px;
  }
}


@media  (max-width: 400px) {

  /* .content{
    display: none;
   
  }

  .linked{
    width: 110px;
    overflow-wrap: break-word;
    font-size: xx-small;
  }






.a{
  display: none;
}

.phone{
  font-size: small;
  
}



.smallIcons{
  font-size: 20px;
  
}
.iconStyles{
  font-size: 25px;
}

.content2{
  display: flex;
    flex-direction: row;
    align-items: center;
    width:97.5%;
    justify-content: space-between;
}; */




}