

.filter_el{
    margin-left: 10px;
  }
  
  
  
  .filter_label{
      color:rgb(27,68,128);
      font-size: larger;
      margin-top: 19px;
  }
  
  
  .filter_list{
     
      margin-top: 10px;
      width: 100%;
      height: 30%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      
  }
  
  .filter_column{
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      
  }
  
  .inputs{
      margin-top: px;
  }
  
  .status{
      font-weight: bold;
      font-size:x-large;
      color:rgb(27,68,128);
      margin-top: 20px;
  }
  
  
  
  .map2{
      display: flex;
      flex-direction: row;
      
      justify-content: space-between;
  }
  
  .regStat{
      display: flex;
      flex-direction: row;
      margin-left: 30px;
  }
  
  .filter_label2{
      color:rgb(27,68,128);
      font-size: larger;
      margin-top: 17px;
  }
  
  
  .filter_column2{
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-left: 20px;   
  }
  
  .inputs2{
      margin-top: 10px;
      margin-left: 20px;
  }
  
  .rowInRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      color:rgb(27,68,128);
      width: 100%;
  }
  
  .rowInRow2{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      color:rgb(27,68,128);
      width: 100%;
      justify-content: end;
      margin-left: -5%;
  }
  
  .label{
      width: 160px;
      font-size: large;
  }
  
  .row{
      display: flex;
      flex-direction: row;
      width: 97%;
      justify-content: space-between;
      margin-top: 10px;
      height: 250px;
      margin-left: 3%;
  
     
  }
  
  .column{
      display: flex;
      flex-direction: column;
      height: 180px;
      width: 50%;
      justify-content: space-between;
  }
  
  .regulatory{
      color:rgb(27,68,128);
      font-size: x-large;
  }
  
  .slashed{
      margin-left: 50px;
  }
  
  
  .guidance{
      inline-size: 80px; 
      overflow-wrap: break-word;
      
  }
  
  
  .filterComponent{
      height:300px;
  
      width:1200px;
      display:block;
      
     
      border-collapse: collapse; 
      overflow:auto;
  
  }
  
  
  
  
  .loader {
  
  
      margin: auto;
    
    
      border: 20px solid #EAF0F6;
    
    
      border-radius: 50%;
    
    
      border-top: 20px solid rgb(27,68,128);
    
    
      width: 30px;
    
    
      height: 30px;
    
    
      animation: spinner 4s linear infinite;
    
    
    }
    
    
     
    
    
    @keyframes spinner {
    
    
      0% { transform: rotate(0deg); }
    
    
      100% { transform: rotate(360deg); }
    
    
    }
  
  @media (max-width:1700px){
      .label{
          width: 130px;
      }
  }
  
  @media (max-width:1350px){
      .row{
          flex-direction: column;
          height: 500px;
          justify-content: start;
      }
  
     
  
      .column{
  
          height: 250px;
      }
  }
  
  @media (max-width:600px){
      .rowInRow2{
          width: 400px;
      }
  }
  
  
  @media (max-width:500px){
      .rowInRow2{
          width: 340px;
      }
  }
  
  
  @media (max-width:420px){
      .rowInRow2{
          width: 320px;
     
      }
  }
  
  
  @media (max-width:350px){
      .rowInRow2{
          width: 290px;
     
      }
  }