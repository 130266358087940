.tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   top: 200px;
   right: -25%;
   position: fixed;
}

.block{
    display: flex;
    flex-direction: row;
    height:90vh;
}

.menu{

    width: 100%;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    
}

.selector{
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-top: 50px;
    margin-bottom: 30px;
}

.providerBlock{
    margin-left: 5%;
    height: 100px;
    width: 90%;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #f3f1f1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.name{
    margin-left: 5%;
    color:#737373;
    font-weight: bold;
}

.created{
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20%;
    justify-content: center;
}

.modified{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}

.blobHolder{
    min-height: 145px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .firstBlob{
    position: absolute;
    height: 1200px;
    left: -500px;
    top: -250px;
    opacity: 0.6;
  }
  
  .secondBlob{
    position: absolute;
    height: 1800px;
    right: -1300px;
    top: -110px;
    transform: rotate(-115deg);
    opacity: 0.6;
  }

  .blobText{
    position: absolute;
    top:55px;
    left: 5%;
    color:white;

  }

  .blobTitle{
    font-size: 50px;
    font-weight: bold;
  }
  .table_elements{
    display: flex;
    width: 90%;
    justify-content:space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 5%;
}



.next_but{
    margin-left:10px;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}

.next_but_dis{
    margin-left:10px;
    background-color:rgb(228, 228, 228) ;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}


.pager{
    display: flex;
    flex-direction: row;
    color:rgb(27,68,128);
    
    
   

}

.page_of_pages{
 color:rgb(27,68,128)
}

.page_buttons{
    border:none;
    color:rgb(27,68,128);
    font-size: large;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.page_buttons_selected{
    border:none;
    color:white;
    font-size: large;
    margin-left: 10px;
    background-color: rgb(27,68,128);
    border-radius: 5px;
}