.block{
    scroll-behavior: smooth;
}

.fragment{
    background-color: green;
}

.results{
    font-size: xx-large;
    font-weight: bold;
    color:rgb(27,68,128);
    
    margin-top: 40px;
  
}

.table_elements{
    display: flex;
    justify-content:space-between;
    margin-top: 30px;
    margin-left:25px;
}


.checker{
    height: 30px;
    width: 30px;
    background-color: white;
    border: solid 1px gray;
    border-radius: 10px;
    position: relative;
    
}

.checked{
    height: 20px;
    width: 20px;
    color: #98cf6d;
    background-color: white;
    pointer-events: none;
    position: absolute;
    top:5px;
    left: 4px;

}

thead{
    position: relative;
    z-index: 1;
}

.next_but{
    margin-left:10px;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}

.next_but_dis{
    margin-left:10px;
    background-color:rgb(228, 228, 228) ;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    color: white;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 80px;
}


.pager{
    display: flex;
    flex-direction: row;
    color:rgb(27,68,128);
    
    
   

}

.page_of_pages{
 color:rgb(27,68,128)
}

.page_buttons{
    border:none;
    color:rgb(27,68,128);
    font-size: large;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.page_buttons_selected{
    border:none;
    color:white;
    font-size: large;
    margin-left: 10px;
    background-color: rgb(27,68,128);
    border-radius: 5px;
}


.regExp{
  
    height: 20px;
    width: 30px;
   
    color: #64696c;

}

.regBut{
    border: none;
    background-color: inherit;
    height: 10px;
  
}


.table_block{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    width:100%
    
}



.selectedNams{
    width: 95%;
    margin-left: 2.5%;
    height: 40px;
    margin-top: 20px;
}

.validated{
    color:white;
    background-color: rgba(55, 170, 60, 0.68);
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height:25px;
    width: 70%;
   
}

.singleNam{
    width: 200px;
    height: 50px;
    background-color: rgba(55, 170, 60, 0.68);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;   
    text-align: center; 
}

.tables{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.singleNamText{
    width: 190px;
    height: 25px;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
    color: white;
    font-weight: bold;
}
.not_validated{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgb(168, 88, 82);
    
    border-radius: 10px;
    height:25px;
    width: 70%;
   
}

.details{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #525f66;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    border-radius: 10px;
    height:25px;
    width:90px;
    border:none;
    font-size: small;
    position: relative;
    z-index: 0;
    
}

.detLink{
    text-decoration: none;
    display: flex;
    
    align-items: center;
    justify-content: center;
}



.table_block table{
    margin-top: 10px;
    display:block;
    height:370px;
    
   
   
    
    border-collapse: collapse; 
    overflow:auto;
    position: relative;

   
}

.nfTable{
    height: 530px!important;
    width: 100%;
}

.fTable{
    height: 370px!important;
    width: 100%;
}




  



tr{
    border-bottom: 1px solid rgb(79,79,79);
    height: 60px;
    background-color: white;
   z-index: -1;
}

td{
    font-size: 12px !important;
   
}

.listWBut{
    display: flex;
    flex-direction: row;
    align-items: center;
}

tbody{
    position: relative;
    z-index:0;
}

tbody tr:nth-child(odd) {
    background-color: #ebebeb;
    color: #000000;
  }
  





@keyframes loading{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 1;
    }

    100%{
        opacity: 0%;
    }
}





  .ring
{
  
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:rgb(27,68,128);
  letter-spacing:4px;
  text-transform:uppercase;
  margin-top: 250px;
  margin-left: 50px;
  
 
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid rgb(27,68,128);
  border-right:3px solid rgb(27,68,128);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}




@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }

  
}

.rowLabel{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-left: 3%;
    font-size: large;
    margin-bottom: 20px;
}

.nameLabel{
    color:rgb(27,68,128);
    font-weight: 400;
    width: 160px;

    
    
}

.list{
    width: 35%;
}

th{
    text-align: start;
    color:rgb(27,68,128);;
    font-size: large;
    height:70px;
    width: 1%;
    background: white;
  position: sticky;
  top:0;
  z-index: 1;
   
    
}

.blocked{
    color: rgb(255, 255, 255);
    position:absolute;
    top:19%;
    left: 77%;
    font-size: 13px;
}

.detailsTH{
    
    text-align: center;
}

.details{
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.owned{
    color: rgb(255, 255, 255);
    position:absolute;
    top:6%;
    left: 75%;
    font-size: 18px;
    
}

.horizontal{
    width: 140px;
    height: 2.5px;
    background-color:rgb(27,68,128) ;
    border-radius: 10px;
}

.underTableSign{
    width: 300px;
    margin-left: calc( 50% - 150px );
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttonSign{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: none;
    background-color: #737373;
}

.sign{
    color:white;
    font-size:35px
}

.signText{
    color: #737373;
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}

.secondTable{
    width: 100%;
    margin-top: 20px;
}

.blobTitle{
    font-size: 27px;
    font-weight: bold;
    margin-top: 120px;
  }

@media (max-width:1700px){
    .nameLabel{
       
        width: 130px;
    }
}

@media (max-width: 1500px) {
    th{
        font-size: medium;
    }
  }

@media (max-width: 1450px) {
  
  }

  @media (max-width: 1300px) {
    th{
        font-size: small;
    }
  }

  @media (max-width: 900px) {
    .table_block{
        width: 110%;
        margin-left: -20px;
    }

    .results{
        font-size: x-large;
        
        margin-left: 0px;
    }

    .horizontal{
        width: 120px;
        margin-left: 0px;
    }
  }

  @media (max-width: 700px) {
    
  }



  @media (max-width: 650px) {
    .pager{
        display: none;
    }
  }


  @media (max-width: 650px) {
    .pager{
        display: none;
    }
  }

  @media (max-width:600px){

    .nameLabel{
   
        width: 62px;
    }
  }


    @media (max-width:500px){

    .nameLabel{
   
        width: 42px;
    }
  }

   @media (max-width:420px){

    .nameLabel{
   
        width: 22px;
    }
  }


  @media (max-width: 400px) {
    .table_block{
        width: 110%;
        margin-left: -14px;
    }

    .results{
        font-size: x-large;
        
        margin-left: 0px;
    }

    .horizontal{
        width: 120px;
        margin-left: 0px;
    }
  }
