.block{
  
    
    display: flex;
   
    align-items: center;
    padding-bottom: 100px;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;

}



.logBox{
    height:600px;
    width:500px;
    background-color: #525f66;
    border-radius: 20px;
    color:white;
    position: relative;
    margin-top:20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
    margin-left: auto;
    margin-right: auto;
}

.title{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: x-large;
    margin-top: 1%;
}

.loginTitle{
    margin-top: 3%;
    width: 100%;
    text-align: center;
    font-size: larger;
   
}



.boxMeat{
    margin-top:2%;
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
    
}

.textJunk{
    width:100%;
    background-color: rgb(213, 163, 163);
    display: flex;
    justify-content: center;
    
}

.logForm{
    display: flex;
    flex-direction: column;
}

.logFormElement{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
    
}


.logMinBox{
    
 width:95%;
 
 
 display: flex;
 align-items: center;
 justify-content: center;
 
}

.formBox{
    
    width: 80%;
}

.downBar{
    background-color: rgb(82, 95, 102);
    height:80px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.icon{
    position: fixed;
    left: 0;
    top:0;
    width: 200px;
    
overflow:hidden;
    
}

.iconImage
{
float:left,top ;
width:100%;
position:relative;
height:auto;
margin-left:-20% ;
margin-top:-20%;
}

.iconSecond{
    position: absolute;
    left: 83%;
    top:50%;
    width: 40%;
    height: auto;
    z-index: -1;
    overflow:hidden;
    background-color: red;
    display: none;
    
    
}

.iconSecondImage{
    float:left,top ;
width:100%;
position:relative;
height:auto;
margin-left:-20% ;
margin-top:-10%;

}

.iconTitle{
    
    border-radius: 50%;
    width: 60px;
    height: 60px;
}


.NanoNams{
    margin-left: 10px;
}
.email{
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    width:100%;
    font-size: 14px;
    padding-left: 10px;
}

.password{
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    width:100%;
    font-size: 14px;
    padding-left: 10px;
}

.buttons{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
}

.logButton{
    width:40%;
    height: 40px;
    background-color: rgb(132, 179, 116);
    border: none;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
}

.registerButton{
    width: 100%;
    height: 100%;
    margin-right: 7%;
    background-color: rgb(56, 111, 179);
    border: none;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.2);
}

.password_reset{
    margin-top: 20px;
    font-size: small;
    margin-left: 52%;

}

.password_reset_button{
    color: white;
    border: none;
    background-color: #525f66;
}

.password_reset_button:hover{
    background-color: #525f66;
}

.registerLink{
    width: 40%;
    text-decoration: none;
    color: white;
}

.resetLink{
    width: 100%;
    text-decoration: none;
    color: white;
}
.error{
    margin-top: 10px;
    font-size: small;
}

.logo{
    margin-top:20px;
    margin-left: calc( 50% - 115px );
    width:200px
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.5;
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0.5;
  }
  
::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0.5;
  }


  .tlo{
     width:1100px;
    transform: rotate(260deg);
    opacity: 0.2;
    z-index: -1;
    top: 30%;
    right: -15%;
    position: fixed;
}

.googleLogin{
    background-color: white;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    position: relative;
    border-radius: 10px;
    border-width: 0px;

}

.googleIcon{
    position: absolute;
    left:10px
}

.googleLoginText{
    width: calc(100% );
    font-size: 20px;
}


@media(max-width:600px){
    .logBox{
        width: 400px;
        height:500px
    }
    .password_reset_button{
        width: 180px;
    }
    .logo{
       
        width: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    .googleLogin{
        height: 45px;
        position: relative;
        border-radius: 10px;
        border-width: 0px;
    
    }
    .password_reset{
        margin-top: 10px;
    }
}


@media(max-width:450px){
    .logBox{
        width: 300px;
        height: 450px;
    }

    .password_reset{
        margin-left: 115px;
    }

    .logo{
       
       margin-top: 0px;
    }

    .NanoNams{
        font-size: large;
    }

   .password_reset_button{
    font-size: x-small;
    width: 120px;
   }
   .googleLoginText{
    font-size: 15px;
}
.googleLogin{
    margin-top: 5px;
    height: 40px;
}

}