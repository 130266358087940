.bar{
    margin-left: 5px;
}

.mypanel > [class~="p-dropdown"] {
    background-color: #07c4e8;
    color: #ffffff;
    width: 250px;
    
  }
  
  
  .mypanel > [class~="p-dropdown-label"] {
    
    width: 250px;
    
  }
  
 