.tlo{
   
    width:1300px;
   transform: rotate(230deg);
   opacity: 0.2;
   z-index: -1;
   top: 200px;
   right: -25%;
   position: fixed;
}

.modalBlure {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
  }

.block{
    display: flex;
    flex-direction: row;
    height:90vh;
}

.menu{

    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    
    
}
.selectNamsTitle{
    font-weight: bold;
    margin-top: 40px;
}

.menuBlock{

    
}
.locLabel{
    font-size: large;
    color:#737373;
    margin-left: 5%;
    width: 170px;
    margin-top: 20px;
}

.title{
    font-size: 27px;
    color:#737373;
    margin-top: 40px;
    font-weight: bold;
    
}

.background{
    background-color: #f3f3f3;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    border-radius: 10px;
}
.subModalBox {
    background-color: transparent;
    margin: calc( 25% - 100px ) auto; 
    padding: 20px;
    border: 2px solid rgba(209, 209, 209, 0.5);
    border-radius: 10px;
    width: 600px;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(40px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }
.desc{
    height:265px;
    width: calc( 95% - 100px );
    background-color: rgb(255, 255, 255);
    border:solid;
    border-color: rgb(197, 197, 197);
    border-radius: 5px;
    border-width: 1px;
    padding-left: 10px;
    margin-right: 5%;
    margin-bottom: 20px;
   
}

.scope{
  height:100px;
  width: calc( 95% - 100px );
  background-color: rgb(255, 255, 255);
  border:solid;
  border-color: rgb(197, 197, 197);
  border-radius: 5px;
  border-width: 1px;
  padding-left: 10px;
  margin-right: 5%;
  margin-bottom: 20px;
 
}

.compColOne{
    width: 50%;
}

.ref{
    width: 350px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    padding-left: 10px;
}

.label{
    font-size: large;
    color:#737373;
    margin-left: 5%;
    width: 170px;
}

.subtitle{
    color:#737373;
    font-size: 22px;
}

.subtitle2{
    margin-top: 50px;
    font-size: x-large;
    color:rgb(27,68,128);
}

.locationRow{
    display: flex;
    flex-direction: column;
  
    
}

.location{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
}



.input{
    height: 40px ;
    width: 60%;
    background-color: white;
    padding-left: 10px;
    
}

.locInp{
    width: calc( 60% + 20px );
    margin-left: -30px;
}

.mainRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    
}

.descRow{
    align-items: start;
}

.lastRow{
    margin-top: 30px;
    margin-bottom: 20px;
}

.sendBut{
    height: 40px;
    width: 150px;
    background-color: #94C66B;
    color:white;
    border:none;
    border-radius: 5px;
    margin-left: calc( 50% - 75px );
    margin-top: auto;
    margin-top: 20px;

}

.metaItem{
    margin-top: 20px;
}

.blobHolder{
    min-height: 145px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .firstBlob{
    position: absolute;
    height: 1200px;
    left: -500px;
    top: -250px;
    opacity: 0.6;
  }
  
  .secondBlob{
    position: absolute;
    height: 1800px;
    right: -1300px;
    top: -110px;
    transform: rotate(-115deg);
    opacity: 0.6;
  }

  .blobText{
    position: absolute;
    top:55px;
    left: 5%;
    color:white;

  }

  .blobTitle{
    font-size: 50px;
    font-weight: bold;
  }

  .form{
    margin-left: 5%;
    width: 90%;
  }
  .modalText{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color:#737373;
  }

  .checkIcon{
    color: #94C66B;
    font-size: 40px;
    margin-right: 10px;
  }


  .okBut{
    margin-top: 20px;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    background-color: #94C66B;
    border: none;
    color:white;
    font-weight: bold;
  }

  .errorIcon{
    color:rgb(193, 47, 47);
    font-size:35px;
    margin-right: 10px;
  }

  .errorBut{
    margin-top: 20px;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    border: none;
    background-color: rgb(149, 147, 147);
    color:white;
    font-weight: bold;
}

.logo{
  width: 40px;
}

.logoInput{
  margin-left: 5px;
  width: calc( 60% - 45px );
  padding-left: 10px;
}