.block{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 95%;
    margin-left: 5%;
    margin-top: 20px;
}

.label{
    
    font-weight: bold;
    font-size: 18px;
}

.data{
   border: solid 2px rgb(197, 197, 197) ;
   width: 90%;
   height: 50px;
   border-radius: 15px;
   display: flex;
   align-items: center;
   padding-left: 20px;
   font-weight: bold;
   background-color: white;
   
}

