.row{
    display: flex;
    flex-direction: row;
    margin-top:10px;
    align-items: center;

}

.genElement{
    margin-left: 50px;
    width:150px;
    overflow-wrap: break-word;
}

.subTitle{
    font-weight: bold;
    width: 100px;
}

.breakAop{
    margin-top: 50px;
}

.subTitle2{
    font-weight: bold;
    width: 300px;
}
.genElementReg{
    margin-left: 50px;
    display: block;
    
    width:150px;
    
    max-height: 70px;
    overflow: auto;
}