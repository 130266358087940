.block{
    margin-top: 50px;
}
.search{
    font-size: 1.3em;
    color: #515F65;
    font-weight: bold;
}

.filterList{
    margin-left: 20px;
    margin-top: 20px
}
.filterItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.filterLabel{
    font-size: 1.1em;
    font-weight: bold;
    color: #515F65;
    width: 75px;
}

.select{
    width: calc( 100% - 85px);
}

@media (min-width:650px){
    .filterItem{
        width: 70%;
    }
}
@media (min-width:1000px){
    .filterItem{
        width: 50%;
        max-width: 600px;
    }
}