.block{
   
    width: 100vw;
    padding-bottom: 100px;
    overflow-y: auto;
    
    display: flex;
    flex-direction: row;
    margin: auto;
}

.break{
    height: 20px;
}


.back{
    color: rgb(97, 97, 97);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10%;
    margin-left: 10%;
    font-size: 17px;
}

.firstSection{
    width: 33%;
    height: 100%;
   
}

.secondSection{
    width: 67%;
    margin-left: auto;
    background-color: rgb(246, 246, 246);
    min-height:calc( 100vh - 210px );
    

}

.row{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
    height: fit-content;
}

.label{
    color:rgb(97, 97, 97);
    font-weight: bold;
    font-size: 28px;
    margin-left: 10px;
}

.labelMargin{
    margin-top: calc( 10% + 50px );
    margin-left: 5%;
}

.userBox{
    color:white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border:none;
    color:rgb(97, 97, 97);
}

.companyBox{
    background-color: white;
    color:rgb(97, 97, 97);
    width: 30%;
    display: flex;
    flex-direction: column;
}

.arrow{
    font-size: 24px;
}

.accountBox{
 
    color:rgb(97, 97, 97);
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: calc( 50% - 175px );
    margin-top: 50px;
}

.infoBox{
    border-radius: 15px;
    margin-top: 20px;
}

@media (max-width:1850px){
    .block{
        width: 1600px;
    }
}

@media (max-width:1650px){
    .block{
        width: 1500px;
    }
}

@media (max-width:1550px){
    .block{
        width: 1400px;
    }
}

@media (max-width:1450px){
    .block{
        width: 1300px;
    }
}

@media (max-width:1350px){
    .block{
        width: 1100px;
    }
}

@media (max-width:900px){
    .block{
        flex-direction: column;
    }

    .userBox{
        margin-top: 20px;
    }
    .companyBox{
        margin-top: 20px;
    }
}


@media (max-width:500px){
    .userBox{
        width: 310px;
    }
    .companyBox{
        width: 310px;
    }

    
    

   
}


@media (max-width:400px){
    .userInfo{
        width: 270px;
    }
    
}